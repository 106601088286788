import { Modal } from "antd"
import Api from "../../../utils/Api"
import { ApiCode, ServerWorker } from "../../../data/common"
import events from "../../../utils/events"
import { AppEvent, AppMessageType } from "../../../data/events"
import { useState } from "react"
import { GlobalUtil } from "../../../utils/GlobalText"
export interface Props {
    show: boolean
    workers: ServerWorker[] | null | undefined
    onClick: (ok: boolean) => void
}
export const DeleteWorkersModal: React.FC<Props> = (props) => {
    let [loading, setLoading] = useState(false)
    return <Modal
        className="small_modal"
        title={GlobalUtil.t("tip")}
        open={props.show}
        centered={true}
        maskClosable={false}
        confirmLoading={loading}
        onOk={async () => {
            setLoading(true)
            let ids = ""
            props.workers?.forEach((v) => {
                if (ids) {
                    ids += ','
                }
                ids += v.workerId
            })

            try {
                // let res = await Api.deleteGroup(ids)
                let res = await Api.deleteWorkers(ids)
                if (res.code === ApiCode.SUCCESS) {
                    events.emit(AppEvent.SHOW_MESSAGE, {
                        type: AppMessageType.SUCCESS,
                        message: GlobalUtil.t("successTip")
                    })
                    props.onClick(true)
                } else {
                    events.emit(AppEvent.SHOW_MESSAGE, {
                        type: AppMessageType.ERROR,
                        message: res.msg ?? GlobalUtil.t("errorTio")
                    })
                }
            } catch (e) {
                events.emit(AppEvent.SHOW_MESSAGE, {
                    type: AppMessageType.ERROR,
                    message: GlobalUtil.t("errorTio")
                })
            }
            setLoading(false)
        }}
        onCancel={() => {
            props.onClick(false)
        }}
        okText={GlobalUtil.t("confirm")}
        cancelText={GlobalUtil.t("cancel")}
    >
        <p>{GlobalUtil.t("work.deleteAccountTip")}</p>
    </Modal>
}