import { Modal } from "antd"
import { ApiCode, ServerGroup } from "../../../data/common"
import Api from "../../../utils/Api"
import { useState } from "react"
import { AppEvent, AppMessageType } from "../../../data/events"
import events from "../../../utils/events"
import { StyleUtils } from "../../../utils/Style"
import { GlobalUtil } from "../../../utils/GlobalText"

export interface Props {
    show: boolean
    onClick: (ok: boolean) => void
    group: ServerGroup | null | undefined
}
export const RemoveGroupModal: React.FC<Props> = (props) => {
    const [loading, setLoading] = useState(false)
    return <Modal
        className="small_modal"
        title={GlobalUtil.t('tip')}
        maskClosable={false}
        confirmLoading={loading}
        open={props.show}
        centered={true}
        onOk={async () => {
            setLoading(true)
            try {
                let res = await Api.deleteGroup(props.group?.groupId ?? "")
                if (res.code === ApiCode.SUCCESS) {
                    events.emit(AppEvent.SHOW_MESSAGE, {
                        type: AppMessageType.SUCCESS,
                        message: GlobalUtil.t("successTip")
                    })
                    props.onClick(true)
                } else {
                    events.emit(AppEvent.SHOW_MESSAGE, {
                        type: AppMessageType.ERROR,
                        message: res.msg ?? GlobalUtil.t("errorTip")
                    })
                }
            } catch (e) {
                events.emit(AppEvent.SHOW_MESSAGE, {
                    type: AppMessageType.ERROR,
                    message: GlobalUtil.t("errorTip")
                })
            }
            setLoading(false)
            props.onClick(true)
        }}
        onCancel={() => {
            props.onClick(false)
        }}
        okText={GlobalUtil.t("confirm")}
        cancelText={GlobalUtil.t("cancel")}
    >
        <p style={{ color: StyleUtils.theme().fontColor_1 }}>{`${GlobalUtil.t('account.confirm_delete_group')} ${props.group?.groupName}`}?</p>
    </Modal>
}