import { Row, Image, Col } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import StickyBox from "react-sticky-box";
import { GlobalUtil } from "../../utils/GlobalText";
import { StyleUtils } from "../../utils/Style";
import { UserInfoManager } from "../../utils/UserInfoManager";
import { AppButton, SearchFeat } from "../../views/commonview";
import LanguageView from "../../widget/LanguageView";
import StyleModeView from "../../widget/StyleModeView";
import UserView from "../../widget/UserView";
import { useState } from "react";
import ShareUserView from "../../widget/ShareUserView";


export const PcHeader = () => {
  const location = useLocation();
  const navigator = useNavigate();
  const [activePage, setActivePage] = useState("");
  const handleMenuClick = (e: any) => {
    let path = e.target.getAttribute("data-key");
    setActivePage(path);
  };

  const linkData = [
    {
      "title": GlobalUtil.t('appbar.home'),
      "dataKey": "home",
      "link": "/home"
    },
    {
      "title": GlobalUtil.t('appbar.workmanager'),
      "dataKey": "management",
      "link": "/work"
    },
    {
      "title": GlobalUtil.t('appbar.income'),
      "dataKey": "income",
      "link": "/revenue"
    }
  ]

  return (
    <StickyBox offsetTop={0} offsetBottom={20} style={{ zIndex: 99 }}>
      {!location.pathname.startsWith("/miner") &&
        <nav className="navbar pc" style={{
          background: StyleUtils.theme().boardBg
        }}>
          <Row justify={"space-between"} style={{ width: "100%", flexFlow: "row nowrap" }}>
            <Col className="navbar-area">
              <div className="nav-logo">
                <Image
                  preview={false}
                  // width={"7.5625rem"}
                  width={"1.6875rem"}
                  height={"1.6875rem"}
                  src={'../../../logo.jpg'}
                  style={{ cursor: "pointer", }}
                  onClick={() => {
                    navigator("/")
                  }} />
              </div>

              <Row className="nav-wrapper" >
                {
                  linkData.map((v, index) => {
                    let isActive = ((v.dataKey === activePage || !activePage) && location.pathname === v.link);
                    if (v.dataKey === "home" && location.pathname === "/") {
                      isActive = true
                    }
                    return (

                      <div className="nav-boxw" key={index}>
                        <Row>
                          <div className="nav-box" key={index} style={{ borderRadius: StyleUtils.getWidth(4) }}>
                            <Link to={v.link} data-key={v.dataKey} className="nav-link" style={{
                              color: isActive ? StyleUtils.theme().mainButtonColor : StyleUtils.theme().fontColor_2,
                            }} onClick={handleMenuClick}>
                              {v.title}
                            </Link>
                            <div className="nav-selected" style={{ background: isActive ? StyleUtils.theme().mainButtonColor : "", }} />
                          </div>
                        </Row>
                      </div>

                    )
                  })
                }
              </Row>

            </Col>
            <SearchFeat data={UserInfoManager.currencyList} />
            <Col className="nav-user" >
              {/* HIDE: */}
              {/* <Row className="user-help" >
                <img style={{ marginRight: '.25rem' }} src="/assets/icon_issue.svg" width={18} alt="" />
                <div style={{ color: StyleUtils.theme().fontColor_2, }}>{GlobalUtil.t('appbar.help')}</div>
              </Row> */}
              <div id="language">
                <LanguageView />
              </div>

              {/* {UserInfoManager._login && <CustomizedDropdownButton />} */}
              <div id="account">
                <UserView />
              </div>
              <StyleModeView />
            </Col>
          </Row>
        </nav>
      }
      {location.pathname.startsWith("/miner") && <nav className="navbar pc" style={{
        background: StyleUtils.theme().boardBg
      }}>
        <Row justify="space-between" align="middle" style={{ width: "100%" }}>
          <Col className="navbar-area" span={6}>
            <div className="nav-logo">
              <Image
                preview={false}
                // width={"7.5625rem"}
                width={"1.6875rem"}
                height={"1.6875rem"}
                src={'../../../logo.jpg'}
                style={{ cursor: "pointer", }}
                onClick={() => {
                  navigator("/")
                }} />
            </div>

          </Col>
          <Col span={8}>
            <SearchFeat data={UserInfoManager.shareCurrencyList} isShare={true} />
          </Col>
          <Col className="nav-user">
            <div id="language">
              <LanguageView />
            </div>
            <ShareUserView />
            <AppButton onClick={() => {
              UserInfoManager.initWithLogin()
              navigator("/home")
            }} text={GlobalUtil.t("appbar.backlogin")} />
            <StyleModeView />
          </Col>
        </Row>
      </nav>
      }

    </StickyBox>
  )
}