import { Col, Divider, Row, Space } from "antd"
import { Link } from "react-router-dom"
import { StyleUtils } from "../../utils/Style"

export const MobileFooter = () => {
    return (
        <Col className="footer-mob" style={{ backgroundColor: "#071629" }}>
            <Row justify={"space-between"} align={"middle"}>

                {/* <img width={146} height={32} src="/assets/white_logo.png" alt="" /> */}
                <img width={32} height={32} src="../../../logo.jpg" alt="" />
                <ul className="footer-icon">
                    <li><a href="https://twitter.com/DRpool_Services" target='blank' ><img src="/assets/twitter.svg" alt="" /></a></li>
                    <li><a href="https://github.com/" target='blank' ><img src="/assets/github.svg" alt="" /></a></li>
                    <li><a href="https://discord.gg/r2zNGKmT" target='blank'><img src="/assets/discord.svg" alt="" /></a></li>
                    <li><a href="mailto:drpool_service@outlook.com" target='blank' ><img src="/assets/email.svg" alt="" /></a></li>
                </ul>
            </Row>
            <Divider style={{ borderBlockColor: StyleUtils.theme().dividerColor, margin: "2rem 0" }} />
            <Col span={5} className="footer-nav-col">
                <span>
                    About
                </span>
                <Link to={""}>HashPool</Link>
                <Link to={""}>API</Link>
                <Link to={""} style={{ whiteSpace: "nowrap" }}>Quick Start</Link>
            </Col>
            <Divider style={{ borderBlockColor: StyleUtils.theme().dividerColor, margin: "2rem 0" }} />
            <Col span={6} className="footer-nav-col">
                <span style={{ whiteSpace: "nowrap" }}>
                    Help Center
                </span>
                <Link to={""}>Announcement</Link>
                <Link to={""}>FAQ</Link>
            </Col>
            <Divider style={{ borderBlockColor: StyleUtils.theme().dividerColor, margin: "2rem 0" }} />
            <Space className="footer-copyright" style={{ marginBottom: "2rem" }}>
                <Col >Links: Kaspa Qitmeer DigiByte HashPool</Col>
                <Col>2024 by <Link to={""}>hashpool.com</Link> All Right Reserved</Col>
            </Space>
        </Col>
    )
}