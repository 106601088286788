
export enum ApiCode {
    SUCCESS = 200,
    NEED_CHECK = 201,
    NEED_LOGIN = 202,
    FAIL = 203

}

export class Account {
    account: string = ""
    desc: string = ""
    email: string | null = ""
    isMain: boolean = true
    method: string = ""
    hide: boolean = false
    hasTotp: boolean = false
    hasDuo: boolean = false
    hasWebauthn: boolean = false
    isTwofactorOpen: boolean = false
    isMaintainerPass: boolean = false
}



export interface ServerAccountInfo {
    user_name: string
    display_name: string
    method: string
    has_totp: boolean
    has_webauthn: boolean
    has_duo: boolean
}
export interface ServerAccountListInfoWithHashrate {
    description: string | null
    hashrate?: string
    account?: string
}
export interface ServerAccountListInfo {
    id: string
    accountName: string | null
    name: string
    isMain: boolean
    isHide: boolean
    orderNum: number
    description: string | null
    isActiveAlert: boolean
    alertChatid: string | null
    createTime: string
    updateTime: string
}
export interface ServerUserSetting {
    isTwoFatorOpen: boolean
    isMaintainerPass: boolean
}
export interface ServerUserInfo {
    user_name: string
    display_name: string
    method: string | null
    has_totp: boolean
    has_webauthn: boolean
    has_duo: boolean
}

export class Currency {
    name: string = ""
    code: string = ""
    icon: string | null = ""
}

export interface LoginHistory {
    loginTime: string | null
    ip: string | null
    location: string | null
    device: string | null
}

export class OnlyReadData {
    id: string = ""
    name: string = ""
    accounts: string = ""
    currencies: string = ""
    accessPermission: string = ""
    url: string = ""
}

export class CurrencyAddress {

}

export interface ServerWalletAddress {
    address: string | null
    addressDesc: string | null
    awaitAddress: string | null
    awaitAddressDesc: string | null
    isActivePayout: boolean
    resumeTime: string | null
    tip?: string
    awaitSyncOrganizer: boolean
    awaitSyncRecipient: boolean
}
export interface ServerUserWalletStatus {
    account: string
    currentAddress: string | null
    status: boolean
}

export interface GoogleBindInfo {
    base32_secret: string
    otpauth_url: string
}

export interface ServerGroup {
    groupId: string
    groupName: string | null
    groupHashrate: string | null
    hashrateTotal: string | null
    allSize: number
    onlineSize: number
    offlineSize: number
    deadSize: number
}

export interface ServerWorkerHashrateDistribution {
    workerHashrateDistribution: WorkerHashrateDistribution | null
}
export interface WorkerHashrateDistribution {
    unit: string | null
    scale: string | null
    hashrateDistribution: HashrateDistribution[]
}
export class HashrateDistribution {
    leftValue: string = "0"
    rightValue: string = "0"
    workerNum: number = 0
}
export interface ServerSummary {
    hashrate: number
    hashrateLastDay: number
    rejectRate: number
}

export class ServerWorker {
    workerName: string = ""
    groupName: string = ""
    workerId: string = ""
    rejectedRate: number = 0
    lastTime: number = 0
    hashrate15M: number = 0
    hashrate24H: number = 0
    state: number = 0 //1在线，2离线，3失效
}
export interface ServerWorkerInfoResult {
    unit: string
    group: ServerGroup[]
    state?: ServerWorkerState
    hashrate?: ServerWorkerContainer
}
export interface ServerWorkerContainer {
    current: number
    total: number
    records: ServerWorker[]
}

export interface ServerWorkerState {
    allSize: number
    onlineSize: number
    offlineSize: number
    deadSize: number
}


export interface ServerHashrateHistory {
    account: string
    unit: string
    values: ServerHistoryValue[]
}
export interface HashrateHistory {
    key: string
    values: HashrateHistoryItem[]
}
export interface HashrateHistoryItem {
    x: string
    y: string
    title: string
}

export interface ServerHomeBlockInfoContain {
    list?: ServerHomeBlockInfo[]
    moreUrl?: string
}
export interface ServerHomeBlockInfo {
    hash?: string
    height: number
    reward?: string
    time: number
}
export interface ServerHomeHistory {
    values: ServerHistoryValue[]
    account: string
}
export interface ServerHistoryValue {
    time: number
    hashrate: string
    rejectedrate: string
}
export interface HomeAllHistory {
    unit: string
    currency: string
    info: HomeUserInfoHistory
}
export interface HomeUserHistory {
    account: string
    description: string
    hashrateHistory: HomeHashrateHistory[]
    info: HomeUserInfoHistory
}
export interface HomeUserInfoHistory {
    account: string | null
    hashrate24Hour: number
    workerOnlineSize: number
    hashrateHour: number
    workerOfflineSize: number
    workerSize: number
    onlineRate: number
    hashrate: number
    payouts: HomeHistoryPayouts
}
export interface HomeHashrateHistory {
    x: number,
    y: string
    titles: string
}

export interface HomeHistoryPayouts {
    currency: string
    balance: string
    lastDayRevenue: string
    revenue: string
    todayRevenue: string
}


export interface ServerTotalRevenue {
    totalPayout: number
    totalIncome: number
    balance: number
    todayEstRevenue: number
    yesterdayRevenue: number
}

export interface ServerIncomeInfo {
    settleDate: number
    hashrateOfDay: number
    amount: number
    description: string
}
export interface ServerPayoutInfo {
    payoutDate: number
    amount: number
    address: string
    status: number //状态;1:等待处理；2:成功；3:失败
    transactionId: string
}

export interface ServerHomeInfo {
    hashrate: string
    miners: number
    workers: number
    feeRate: string
    blocks: string

}

export interface ServerShareAddress {
    walletAddr: string
    requestUrl: string
}

export interface ServerNonPayoutReasons {
    seizedStatus: boolean
    walletAddressStatus: boolean
    thresholdStatus: boolean
    resumeTimeStatus: boolean
}

export interface ReportListItem {
    id?: string
    currency: string
    type: number
    isPushNotice: boolean
    language: string
    pushAccounts: string
}
export interface BaseResponse<T> {
    msg: string | null | undefined
    code: number
    data: T | null | undefined
}

export interface BaseListItem<T> {
    records: T[] | null | undefined
    size: number
    total: number
    current: number
}
export interface BaseListResponse<T> {
    msg: string | null | undefined
    code: number
    data: BaseListItem<T> | null | undefined
}