import React, { CSSProperties, useCallback, useEffect, useState } from "react";
import { StyleUtils } from "../utils/Style";
import { ConfigProvider, Dropdown, MenuProps, Row, Space, theme, AutoComplete, Tooltip, Input } from "antd";
import { DownOutlined } from '@ant-design/icons';
import { ApiCode, Currency } from "../data/common";
import { UserInfoManager } from "../utils/UserInfoManager";
import { GlobalUtil } from "../utils/GlobalText";
import enUS from 'antd/locale/en_US';
import zhCN from 'antd/locale/zh_CN';
import Api from "../utils/Api";
import _ from 'lodash';
import events from "../utils/events";
import { AppEvent } from "../data/events";


interface PoolButtonlProps {
    text: string
}
const PoolButton: React.FC<PoolButtonlProps> = (props) => {
    return <div style={{ borderColor: "", color: "", paddingLeft: StyleUtils.getWidth(10), paddingRight: StyleUtils.getWidth(10), paddingTop: StyleUtils.getWidth(4), paddingBottom: StyleUtils.getWidth(4), background: "#FAFAFF" }}>

    </div>
}

interface AppMainTextProps {
    text: string
    fontSize?: string
    style?: CSSProperties
}
const AppMainText: React.FC<AppMainTextProps> = (props) => {
    return <div style={{ WebkitTextFillColor: "transparent", WebkitBackgroundClip: "text", display: "inline-block", fontWeight: 500, backgroundImage: StyleUtils.LG_FONT_COLOR, fontSize: props.fontSize ?? "2.4rem", ...StyleUtils.textWrap(), ...props.style }}>
        {props.text ?? ""}
    </div>
}

interface AppButtonProps {
    onClick?: () => (void)
    text: string
    style?: CSSProperties
    disabled?: boolean
    canClick?: boolean
}

const AppButton: React.FC<AppButtonProps> = (props) => {
    return <div style={{ cursor: "pointer", background: props.disabled ? "#E6E6E6" : StyleUtils.theme().mainButtonColor, borderRadius: "8px", color: props.disabled ? "#899199" : "white", fontSize: "0.88rem", alignItems: "center", justifyContent: "center", display: "flex", fontWeight: "500", padding: "8px 12px", ...props?.style }} onClick={() => {
        if (!props.disabled || props.canClick) {
            props.onClick && props.onClick()
        }
    }}>{props.text}</div>
}

interface AntdViewProps {
    children?: React.ReactNode;
}
const AntdInput: React.FC<AntdViewProps> = (props) => {
    return <ConfigProvider
        theme={{
            "token": {
                colorPrimary: StyleUtils.theme().mainButtonColor,
                colorBorder: "",
                colorBgContainer: StyleUtils.theme().boardBg,
                colorTextPlaceholder: StyleUtils.theme().fontColor_placeholder,
                colorText: StyleUtils.theme().fontColor_1,
            },
            algorithm: StyleUtils.isLight ? theme.darkAlgorithm : theme.defaultAlgorithm
        }}

    >
        {props.children}
    </ConfigProvider>
}
const AntdButton: React.FC<AntdViewProps> = (props) => {
    return <ConfigProvider
        theme={{
            "token": {
                colorPrimary: StyleUtils.theme().mainButtonColor,
                colorBorder: "",
                colorTextDisabled: StyleUtils.theme().mainButtonColorFont_Disable,
                colorBgContainerDisabled: StyleUtils.theme().mainButtonDisableColor
            }
        }}
    >
        {props.children}
    </ConfigProvider>
}
const AntdCard: React.FC<AntdViewProps> = (props) => {
    return <ConfigProvider
        theme={{
            "token": {
                colorBorderSecondary: StyleUtils.theme().boardBg,
                colorPrimary: StyleUtils.theme().mainButtonColor,
                colorBorder: "",
                colorBgContainer: StyleUtils.theme().boardBg,
            }
        }}
    >
        {props.children}
    </ConfigProvider>
}
const AntdSelect: React.FC<AntdViewProps> = (props) => {
    return <ConfigProvider
        theme={{
            token: {
                // colorBgContainer: StyleUtils.theme().boardBg,
                // colorBorder: StyleUtils.theme().boardBg,
                // colorBorderSecondary: StyleUtils.theme().boardBg,
                // colorText: StyleUtils.theme().fontColor_1,
                // colorBgMask: StyleUtils.theme().fontColor_1,
            },
            algorithm: StyleUtils.isLight ? theme.darkAlgorithm : theme.defaultAlgorithm
        }}
    >
        {props.children}
    </ConfigProvider>
}

const AntdTable: React.FC<AntdViewProps> = (props) => {
    return <ConfigProvider
        locale={GlobalUtil.getDefaultLanguage() === 'en_US' ? enUS : zhCN}
        theme={{
            token: {
                colorBgContainer: StyleUtils.theme().boardBg,
                colorBorder: StyleUtils.theme().boardBg,
                colorBorderSecondary: StyleUtils.theme().boardBg,
                colorText: StyleUtils.theme().fontColor_1,
                colorBgMask: StyleUtils.theme().fontColor_1,
            },
            algorithm: StyleUtils.isLight ? theme.darkAlgorithm : theme.defaultAlgorithm
        }}
    >
        {props.children}
    </ConfigProvider>
}
const AntdDatePicker: React.FC<AntdViewProps> = (props) => {
    return <ConfigProvider
        theme={{
            algorithm: StyleUtils.isLight ? theme.darkAlgorithm : theme.defaultAlgorithm
        }}
    >
        {props.children}
    </ConfigProvider>
}


const SearchFeat = ({ data, isShare }: any) => {
    const [value, setValue] = useState('');
    const [shareAddress, setShareAddress] = useState<{ value: string; label: any }[]>([]);

    const handleChange = (value: string) => {
        setValue(value);
        if (!value) {
            setShareAddress([]);
        }
    }

    const handleSelect = () => {
        setValue('')
    };

    const handleSearch = async (value: string) => {
        if (!value) return false;
        try {
            let res = await Api.getShareAddress(value);
            if (res.code === ApiCode.SUCCESS) {
                setShareAddress([{
                    value: res.data?.requestUrl || '',
                    label: <Tooltip title={res.data?.requestUrl}>
                        <a href={res.data?.requestUrl} target='_blank'>{res.data?.requestUrl}</a>
                    </Tooltip>,
                }])
            } else {
                setShareAddress([{
                    value: '',
                    label: <Tooltip title={GlobalUtil.t("invalid_address")}>
                        {GlobalUtil.t("invalid_address")}
                    </Tooltip>
                }])
            }
        } catch (error) {

        }
    };

    // const handleSearchDebounce = useCallback(_.debounce(handleSearch, 500), []);

    let selectIndex = 0;
    const currency = UserInfoManager.currency || localStorage.getItem('currency');
    const shareCurrency = localStorage.getItem('shareCurrency');
    if (!data?.length) {
        return <div />
    }

    selectIndex = (data as Currency[]).findIndex((value, index) => {
        if (isShare) {
            if (value.code === shareCurrency) {
                return true
            }
        } else {
            if (value.code === currency) {
                return true
            }
        }
        return false
    })

    const items: MenuProps['items'] = data.map((v: any, index: number) => {
        return {
            key: index,
            label: data ? <div key={index} onClick={() => {
                if (!isShare) {
                    UserInfoManager.updateCurrency(v["code"])
                } else {
                    UserInfoManager.updateShareCurrency(v["code"])
                }
            }} style={{ display: "flex", alignItems: "center", color: StyleUtils.theme().fontColor_1 }}><img style={{ width: 19, height: 19, margin: "0 4px 0 0" }} src={v?.icon} alt="" />{v?.name}</div> : <div />,
        }
    })

    events.on(AppEvent.REFRES, ({ currency, language, account }: any) => {
        if (currency || account) {
            setValue('')
        }
    })

    return <div id="search-feat" style={{ display: "flex", alignItems: "center", flex: "auto", marginRight: "1.5rem" }}>
        <div style={{ display: "flex", width: "100%" }}>
            <div id="currencys" style={{ minWidth: 80, position: 'relative' }}>
                <Dropdown menu={{ items }} trigger={['click']} getPopupContainer={(node) => {
                    return document.getElementById('currencys')!
                }}>
                    <div style={{
                        background: StyleUtils.theme().mainColor, borderRadius: "8px 0 0 8px",
                        textDecoration: "none",
                        cursor: "pointer",

                    }}>
                        <Space align="center" style={{ color: StyleUtils.theme().fontColor_1, fontSize: StyleUtils.getFont(12), padding: "9px" }}>
                            <Row>
                                <img style={{ width: 18, margin: "0 4px 0 0" }} src={data[selectIndex]?.icon} alt="" />
                            </Row>
                            {data[selectIndex]?.name}
                            <DownOutlined />
                        </Space>
                    </div>
                </Dropdown>

            </div>

            <div className='head-search' style={{ display: "flex", flex: "auto", alignItems: "center", justifyContent: "space-between", border: `1px solid ${StyleUtils.theme().mainColor}`, padding: "0 8px", borderRadius: "0 8px 8px 0" }}>
                <AntdInput>
                    <AutoComplete
                        id="search"
                        placeholder={GlobalUtil.t("work.searchTip")}
                        style={{ maxWidth: "98%", flex: "auto", color: StyleUtils.theme().fontColor_2 }}
                        bordered={false}
                        key={'search'}
                        value={value}
                        onChange={handleChange}
                        onSelect={handleSelect}
                        onSearch={handleSearch}
                        options={shareAddress}
                    />
                </AntdInput>
                <img
                    style={{ width: 15, height: 15 }}
                    src={StyleUtils.isLight ? "/assets/dark/search.svg" : "/assets/search.svg"}
                    alt=""
                />
            </div>

        </div>
    </div>
}
export {
    PoolButton, AppMainText, AppButton, AntdInput, AntdButton, AntdTable, AntdCard, SearchFeat, AntdSelect, AntdDatePicker
}