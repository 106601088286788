import { Row, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import { ApiCode, LoginHistory } from '../../../data/common';
import Api from '../../../utils/Api';
import { GlobalUtil } from '../../../utils/GlobalText';
import { UserInfoManager } from '../../../utils/UserInfoManager';
import { StyleUtils } from '../../../utils/Style';
import { AntdTable } from '../../../views/commonview';


interface SecurityData {
    title: string | undefined
    value: string | undefined
    rightIcon: string | null
}

const UsetLoginHistory = () => {
    const columns: ColumnsType<LoginHistory> = [
        {
            title: GlobalUtil.t("personal.time"),
            dataIndex: 'loginTime',
            width: "25%",
            render(value, record, index) {
                return <div style={{ fontSize: "0.88rem", color: StyleUtils.theme().fontColor_1, fontWeight: "400" }}>
                    {value}
                </div>
            },
        },
        {
            title: 'IP',
            dataIndex: 'ip',
            width: "25%",
            render(value, record, index) {
                return <div style={{ fontSize: "0.88rem", color: StyleUtils.theme().fontColor_1, fontWeight: "400" }}>
                    {value}
                </div>
            },
        },
        {
            title: GlobalUtil.t("personal.location"),
            dataIndex: 'location',
            width: "25%",
            render(value, record, index) {
                return <div style={{ fontSize: "0.88rem", color: StyleUtils.theme().fontColor_1, fontWeight: "400" }}>
                    {value}
                </div>
            },
        },
        {
            title: GlobalUtil.t("personal.device"),
            dataIndex: 'device',
            width: "25%",
            ellipsis: true,
            render(value, record, index) {
                return <div style={{ fontSize: "0.88rem", color: StyleUtils.theme().fontColor_1, fontWeight: "400" }}>
                    {value}
                </div>
            },
        }
    ];

    const [data, setData] = useState<LoginHistory[]>([])
    const loadData = async () => {
        try {
            let res = await Api.getLoginHistory()
            if (res.code === ApiCode.SUCCESS) {
                setData(res.data?.records ?? [])
            }
        } catch (e) {

        }
    }
    useEffect(() => {
        loadData()
    }, [])
    return <AntdTable>
        <Table
            dataSource={data}
            columns={columns}
            className={StyleUtils.isLight ? "tableD home-table" : "tableL home-table"}
        />
    </AntdTable>
}

interface InfoItemViewProp {
    title?: string
    text?: string
}
const InfoItemView: React.FC<InfoItemViewProp> = (props) => {
    return <Row style={{ display: "flex", flexFlow: "row nowrap", }}>
        <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: StyleUtils.theme().fontColor_1,
            fontSize: "0.88rem",
            padding: '.5rem .75rem',
            fontWeight: "500",
            borderRadius: "8px 0px 0px 8px",
            background: StyleUtils.theme().user_item_left_color
        }}>
            {props.title}
        </div>
        <div style={{ padding: ".5rem 1.25rem", display: "flex", alignItems: "center", color: StyleUtils.theme().fontColor_1, fontSize: "0.88rem", fontWeight: "400", borderRadius: "0px 8px 8px 0px", background: StyleUtils.theme().mainColor }}>
            {props?.text}
        </div>
    </Row>
}

const UserCenterView = () => {
    let mainAccount = UserInfoManager.mainAccount


    const Items: SecurityData[] = [
        {
            "title": GlobalUtil.t("personal.name"),
            "value": mainAccount?.account,
            "rightIcon": null
        },
        {
            "title": GlobalUtil.t("personal.email"),
            "value": mainAccount?.email ?? "",
            "rightIcon": null
        }
    ]
    return <div style={{
        background: StyleUtils.theme().boardBg,
        paddingTop: "1rem",
        display: "flex",
        flexDirection: "column"
    }}>
        <div style={{ fontSize: "0.9rem", fontWeight: "500", color: StyleUtils.theme().fontColor_2, marginBottom: StyleUtils.getWidth(9) }}>{GlobalUtil.t("personal.title")}</div>
        <Row style={{ display: "flex", flexFlow: "row wrap", gap: "1rem", marginBottom: "18px" }}>
            {
                Items.flatMap((v, index) => {
                    return <InfoItemView key={v.title} title={v.title} text={v.value} />
                })

            }
        </Row>
        <div style={{ height: StyleUtils.getWidth(1), width: "100%", background: StyleUtils.theme().mainColor, }} />
        <div style={{ fontSize: "0.9rem", fontWeight: "500", color: StyleUtils.theme().fontColor_2, marginTop: StyleUtils.getWidth(14), marginBottom: StyleUtils.getWidth(9) }}>{GlobalUtil.t("personal.loginHistory")}</div>
        {UsetLoginHistory()}
    </div>
}

export default UserCenterView