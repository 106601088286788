import { Card, ConfigProvider, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import { ApiCode, ServerHomeBlockInfo, ServerHomeBlockInfoContain } from "../../data/common";
import moment from "moment";
import { ColumnsType } from "antd/es/table";
import Api from "../../utils/Api";
import { AppMainText } from "../../views/commonview";
import { StyleUtils } from "../../utils/Style";

import useBreakpoint from "antd/es/grid/hooks/useBreakpoint"
import { EllipsisMiddle } from "../../components";
import { RightOutlined } from '@ant-design/icons';
import events from "../../utils/events";
import { AppEvent } from "../../data/events";
import { UserInfoManager } from "../../utils/UserInfoManager";
import { GlobalUtil } from "../../utils/GlobalText";


export const NewBlockView: React.FC = () => {
    const { xl } = useBreakpoint()
    const [data, setData] = useState<ServerHomeBlockInfoContain | undefined | null>()
    const columns: ColumnsType<ServerHomeBlockInfo> = [
        {
            title: 'Block Height',
            dataIndex: 'height',
            key: 'blockHeight',
        },
        {
            title: 'Block Hash',
            dataIndex: 'hash',
            key: 'blockHash',
            // align: xl ? 'left' : 'center',
            render: (hash) => {
                return xl ? hash : <EllipsisMiddle style={{ justifyContent: 'flex-start' }} suffixCount={5}>{hash}</EllipsisMiddle>
            }
        },
        {
            title: 'Reward',
            dataIndex: 'reward',
            key: 'reward',
            render: (reward) => {
                return <div style={{ color: StyleUtils.theme().fontColor_1 }}>
                    {Number.parseFloat(reward).toLocaleString(undefined, { minimumFractionDigits: 4 })}
                </div>
            }
        },
        {
            title: 'Time',
            dataIndex: "time",
            key: "time",
            render: (value: number) => {
                return <div >
                    {moment(value * 1000).format('YYYY-MM-DD HH:mm:ss')}
                </div>
            }
        }
    ];
    const [update, setUpdate] = useState(false)
    events.on(AppEvent.REFRES, ({ currency, language }: any) => {
        if (currency || language) {
            setUpdate(!update)
        }
    })
    const loadData = async () => {
        try {
            let res = await Api.getMindBlocks()
            if (res.code === ApiCode.SUCCESS) {
                setData(res.data)
            }
        } catch (e) {

        }
    }
    useEffect(() => {
        loadData()
    }, [update])
    return <div>
        <Row justify={"center"} style={{ color: StyleUtils.theme().fontColor_1, fontSize: "2.4rem", fontWeight: "500", marginTop: StyleUtils.getWidth(36), marginBottom: StyleUtils.getWidth(18) }}>
            Recently mined
            <div style={{ width: StyleUtils.getWidth(3), marginRight: "6px", }} />
            <AppMainText text="Blocks" style={{ textOverflow: "inherit", overflow: "unset" }} />
        </Row>
        <ConfigProvider
            theme={{
                token: {
                    colorBgContainer: StyleUtils.theme().boardBg,
                    colorBorder: StyleUtils.theme().boardBg,
                    colorBorderSecondary: StyleUtils.theme().boardBg,
                    colorText: StyleUtils.theme().fontColor_1,
                    colorBgMask: StyleUtils.theme().fontColor_1,
                }
            }}
        >
            <Card className="home-table">
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", position: "relative", flexDirection: "row", marginBottom: StyleUtils.getWidth(18) }}>
                        <div style={{ fontSize: StyleUtils.getFont(16), fontWeight: "500", color: "222426" }}>
                            {UserInfoManager.getShowCurrency()} - {GlobalUtil.t("home.latestBlocks")}
                        </div>
                        <div style={{ color: "#2F65FF", cursor: "pointer", position: "absolute", right: StyleUtils.getWidth(0) }} onClick={() => {
                            window.open(data?.moreUrl)
                        }}>{GlobalUtil.t("home.viewMore")} <RightOutlined /></div>
                    </div>

                    <Table
                        rowKey={(record) => record.height}
                        className={StyleUtils.isLight ? "tableD" : "tableL"}
                        pagination={false}
                        columns={columns}
                        dataSource={data?.list ?? []}
                    />
                </div>
            </Card>
        </ConfigProvider>
    </div>
}