import { Button, Input, Modal, message, Image, Checkbox, Select, theme } from 'antd';
import React, { useEffect, useState } from 'react';
import { Account, ApiCode, BaseResponse, Currency, GoogleBindInfo, OnlyReadData, ReportListItem, ServerUserWalletStatus } from '../data/common';
import Api from '../utils/Api';
import { GlobalUtil } from '../utils/GlobalText';
import { StyleUtils } from '../utils/Style';
import events from '../utils/events';
import { AppEvent, AppMessageType } from '../data/events';
import { UserInfoManager } from '../utils/UserInfoManager';
import { AntdButton, AntdInput, AntdSelect } from '../views/commonview';

export interface DoubleCheckProps {
    show: boolean
    week: boolean
    onClick: (ok: boolean) => void
    data: ReportListItem | null | undefined
}

const AddReportModel: React.FC<DoubleCheckProps> = (props) => {
    let week = props.week
    if (props.data) {
        week = props.data.type === 1
    }
    const title = week ? GlobalUtil.t("report.week") : GlobalUtil.t("report.month")
    const updateData = props.data
    const [loading, setLoading] = useState(false)
    let [language, setLanguage] = useState<string | null | undefined>(updateData?.language)
    let [syncAccount, setSyncAccount] = useState<string[] | null | undefined>(updateData?.pushAccounts?.split(","))
    let [syncCurrency, setSyncCurrency] = useState<string | null | undefined>(updateData?.currency)
    const disable = !syncAccount?.length || !syncCurrency?.length || !language?.length
    const clear = () => {
        setSyncCurrency(null)
        setSyncAccount([])
        setLanguage(null)
    }
    useEffect(() => {
        setSyncCurrency(updateData?.currency)
        setLanguage(updateData?.language)
        setSyncAccount(updateData?.pushAccounts?.split(","))
    }, [props.data])
    const handleCurrencyChange = (value: string) => {
        setSyncCurrency(value)
    };
    const handleAccountChange = (value: string[]) => {
        setSyncAccount(value)
    };
    const handleLanguage = (value: string) => {
        setLanguage(value)
    };
    return <Modal
        width={"50%"}
        centered={true}
        className='small_modal'
        maskClosable={false}
        open={props.show}
        maskTransitionName=""
        onCancel={() => {
            clear()
            props.onClick(false)
        }}
        footer={[]}
    >
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", flexDirection: "row", color: StyleUtils.theme().fontColor_1 }}>{GlobalUtil.t(updateData ? "report.updateReport" : "report.addReport")} {title}</div>
            <div style={{ marginTop: StyleUtils.getHeight(15) , color: StyleUtils.theme().fontColor_1}}>{title} {GlobalUtil.t('report.language')}</div>
            <div style={{ marginTop: StyleUtils.getHeight(10), }}>
            <AntdSelect>
                    <Select onChange={handleLanguage} style={{ width: "100%" }} value={language} options={[{ "name": "中文", code: "zh_CN" }, { "name": "English", code: "en_US" }].flatMap((v) => { return { "value": v.code, label: v.name } })} />
            </AntdSelect>
            </div>
            {!updateData && <div style={{ marginTop: StyleUtils.getHeight(15), color: StyleUtils.theme().fontColor_1 }}>{title} {GlobalUtil.t('report.currency')}</div>
            }
            {!updateData && <div style={{ marginTop: StyleUtils.getHeight(10), }}>
            <AntdSelect>
                    <Select onChange={handleCurrencyChange} style={{ width: "100%" }} value={syncCurrency} options={UserInfoManager.currencyList.flatMap((v) => { return { "value": v.code, label: v.name } })} />
                    </AntdSelect>
            </div>}
            <div style={{ marginTop: StyleUtils.getHeight(15), color: StyleUtils.theme().fontColor_1 }}>{title} {GlobalUtil.t('report.account')}</div>
            <div style={{ marginTop: StyleUtils.getHeight(10) }}>
            <AntdSelect>
                    <Select onChange={handleAccountChange} mode="multiple" style={{ width: "100%" }} value={syncAccount} options={UserInfoManager.accounts.flatMap((v) => { return { "value": v.account, label: v.account } })} />
                    </AntdSelect>
            </div>
            <div style={{ paddingTop: StyleUtils.getWidth(20), display: "flex", flexDirection: "row" }}>
                <AntdButton>
                    <Button
                        loading={loading} disabled={disable} onClick={async () => {
                            setLoading(true)
                            try {
                                let syncAccounts = ""
                                syncAccount?.forEach((v) => {
                                    if (syncAccounts.length) {
                                        syncAccounts += ","
                                    }
                                    syncAccounts += v
                                })
                                let syncCurrencies = syncCurrency ?? ""
                                let res: BaseResponse<string>
                                if (updateData) {
                                    res = await Api.updateReportList(updateData.id ?? "", syncAccounts, language ?? "")
                                } else {
                                    res = await Api.addReportList(props.week, syncCurrencies, syncAccounts, language ?? "")
                                }
                                if (res.code === ApiCode.SUCCESS) {
                                    events.emit(AppEvent.SHOW_MESSAGE, {
                                        type: AppMessageType.SUCCESS,
                                        message: GlobalUtil.t("successTip")
                                    })
                                    clear()
                                    props.onClick(true)
                                } else {
                                    events.emit(AppEvent.SHOW_MESSAGE, {
                                        type: AppMessageType.ERROR,
                                        message: res.msg ?? GlobalUtil.t("errorTip")
                                    })
                                }
                            } catch (e) {
                                console.log(e)
                                events.emit(AppEvent.SHOW_MESSAGE, {
                                    type: AppMessageType.ERROR,
                                    message: GlobalUtil.t("errorTip")
                                })
                            }
                            setLoading(false)
                        }} type="primary" style={{ width: "100%" }}>{updateData ? GlobalUtil.t("onlyread.update") : GlobalUtil.t("onlyread.create")}</Button>
                </AntdButton>
            </div>

        </div>
    </Modal>
}


export default AddReportModel;