import { Checkbox, Col, Divider, Row, Image, Space, Pagination } from "antd"
import { StyleUtils } from "../../../../utils/Style"
import EChartsReact from "echarts-for-react"
import * as echarts from 'echarts';
import moment from "moment"
import { GlobalUtil } from "../../../../utils/GlobalText"
import { ServerHashrateHistory, ServerHistoryValue, ServerWorker, ServerWorkerInfoResult } from "../../../../data/common"
import { Dispatch, useEffect, useState } from "react";
import { IWorkInfoManager } from "../../../../utils/WorkData";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { CheckboxChangeEvent, CheckboxOptionType } from "antd/es/checkbox";

import { deepCopy } from "../../../../utils/deepCopy";
import { AntdInput } from "../../../../views/commonview";
import events from "../../../../utils/events";
import { AppEvent } from "../../../../data/events";


interface ItemProps {
    setSelectWorker: Dispatch<React.SetStateAction<ServerWorker[]>>
    itemData: ServerWorkerInfoResult | null
    handleExpand: (record: ServerWorker) => void
    expandWorker?: ServerWorker | null | undefined
    historyData?: ServerHashrateHistory | null | undefined
    WorkInfoManager: IWorkInfoManager
}

export const MobileWorkerInfoViewTable = (props: ItemProps) => {
    const [update, setUpdate] = useState(false)
    events.on(AppEvent.GROUP_DATA_CHANGE, () => {
        setCheckAll(false)
        setCheckedList([])
        setUpdate(!update)
    })

    const { setSelectWorker, itemData, handleExpand, expandWorker, historyData, WorkInfoManager } = props
    const [checkedList, setCheckedList] = useState<CheckboxValueType[]>([])
    const [indeterminate, setIndeterminate] = useState(false);
    const [options, setOptions] = useState<string[]>([])
    const [checkAll, setCheckAll] = useState(false);
    const [currentPage, setCurrentPage] = useState(1)
    const handleClickAll = (e: CheckboxChangeEvent) => {
        setCheckedList(e.target.checked ? options : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
        if (e.target.checked && itemData && itemData.hashrate?.records) {
            setSelectWorker(itemData.hashrate?.records)
        } else {
            setSelectWorker([])
        }

    }

    const handleClick = (e: CheckboxChangeEvent, record: ServerWorker) => {
        const { checked } = e.target;

        const list = checked ? [...deepCopy(checkedList), record.workerId] : deepCopy(checkedList).filter((id) => id !== record.workerId)

        setCheckedList(list);
        setSelectWorker((prev) =>
            checked
                ? [...prev, record]
                : prev.filter((item) => item !== record)
        )
        setCheckAll(list.length === options.length)
        setIndeterminate(!!list.length && list.length < options.length)

    }

    useEffect(() => {
        if (itemData?.hashrate?.records) {
            const opts = itemData.hashrate.records.map((record) => record.workerId)
            setOptions(opts)
        }
    }, [itemData])
    useEffect(() => {
        WorkInfoManager.updateSize(1, 100)
    }, [])
    let allData = (itemData?.hashrate?.records ?? [])
    let thisPageData = allData
    if (allData.length) {
        thisPageData = allData.slice((currentPage - 1) * 5, currentPage * 5)
    }
    return (
        <div style={{ display: "flex", flexDirection: "column", alignItems: 'center', padding: '0 8px' }}>
            <Row justify={"space-between"} style={{ flexFlow: "row wrap-reverse", rowGap: "10px", margin: "8px 10px 12px", width: "100%" }}>
                <Checkbox className={StyleUtils.isLight ? "ckd" : "ckl"} style={{ color: StyleUtils.theme().fontColor_1 }} indeterminate={indeterminate} checked={checkAll} onChange={handleClickAll} >
                    Check All
                </Checkbox>
                <AntdInput>
                    <Pagination
                        size="small"
                        total={itemData?.hashrate?.total}
                        current={currentPage}
                        defaultPageSize={5}
                        showSizeChanger
                        showQuickJumper
                        onChange={(page) => {
                            setCurrentPage(page)
                        }} />
                </AntdInput>

            </Row>
            {itemData && thisPageData.map((record, index) => {
                let expand = expandWorker?.workerName === record.workerName
                let columnData = [
                    [GlobalUtil.t("miner"), record.workerName],
                    [GlobalUtil.t("work.15mHashrate"), `${record.hashrate15M} ${itemData.unit}`],
                    [GlobalUtil.t("work.24hHashrate"), `${record.hashrate24H} ${itemData.unit}`],
                    [GlobalUtil.t("work.rejectRate"), `${record.rejectedRate} %`],
                    [GlobalUtil.t("work.groupName"), record.groupName && record.groupName.length ? record.groupName.length : "-"],
                ]
                return (
                    <div key={record.workerId} style={{ width: "100%", display: "flex", padding: "10px 8px", flexDirection: "column", border: `1px solid ${StyleUtils.getBorderColor()}`, marginBottom: "8px", borderRadius: "8px", ...StyleUtils.fontStyle_1() }} >
                        <Row justify={'space-between'} style={{ width: "100%" }}>
                            <Col >
                                <Checkbox
                                    className={StyleUtils.isLight ? "ckd" : "ckl"}
                                    checked={checkedList.includes(record.workerId)}
                                    onChange={(e) => {
                                        handleClick(e, record)
                                    }}
                                />
                            </Col>
                            <Row style={{ gap: '10px', alignItems: 'center' }}>
                                <Col>{moment(record.lastTime).format('YYYY-MM-DD HH:mm:ss')}</Col>
                                <Image
                                    preview={false}
                                    width={24}
                                    style={{ cursor: "pointer", }}
                                    src={!expand ? StyleUtils.ICON_EXPAND_DOWN : StyleUtils.ICON_EXPAND_UP}
                                    onClick={() => { handleExpand(record) }} />

                            </Row>
                        </Row>
                        <Divider style={{ borderBlockStart: `1px solid ${StyleUtils.theme().dividerColor}` }} />
                        <Row style={{ flexDirection: "column", gap: "8px", flexFlow: "column" }} justify={"start"}>
                            {columnData.map((item, index) => (
                                <Row key={index} justify={"space-between"}>
                                    <Col style={{ fontWeight: "500", color: StyleUtils.theme().fontColor_3 }}>
                                        {item[0]}
                                    </Col>
                                    <Space style={{ display: "flex", justifyContent: "center", fontSize: "0.88rem", fontWeight: "400", color: StyleUtils.theme().fontColor_1 }} size={[2, 4]} wrap>
                                        {item[1]}
                                    </Space>
                                </Row>
                            ))}

                            {expandWorker?.workerName === record.workerName &&
                                <>
                                    <Divider style={{ borderBlockStart: `1px solid ${StyleUtils.theme().dividerColor}` }} />
                                    <Row>
                                        <EchartsComponent
                                            expandWorker={expandWorker}
                                            historyItems={historyData?.values ?? []}
                                            workerName={record.workerName}
                                            unit={itemData.unit}
                                        />
                                    </Row>
                                </>
                            }
                        </Row>
                    </div>
                )
            })}

        </div>

    )
}


interface EchartsProps {
    historyItems: ServerHistoryValue[]
    workerName: string
    expandWorker?: ServerWorker | null | undefined
    unit?: string
}
const EchartsComponent = (props: EchartsProps) => {
    const { historyItems, workerName, expandWorker, unit } = props
    const option = {
        color: ["#2F65FF"],
        textStyle: {
            color: StyleUtils.theme().fontColor_1
        },
        tooltip: {
            trigger: "axis",
            axisPointer: {
                type: "shadow",
            },
            formatter: (params: any) => {
                var d = historyItems[params[0].dataIndex]
                return moment(d.time).format('YYYY-MM-DD HH:mm:ss') + "<br>" + parseFloat(d.hashrate).toFixed(2) + ` ${unit}`;
            }
        },
        toolbox: {
            show: false,
            feature: {
                brush: {
                    type: ['lineX'], // 可以设置为 'line' 表示启用直线选择
                }
            }
        },
        brush: {
            toolbox: ['lineX'],
            xAxisIndex: 0,
            nameLocation: "start",
            brushLink: 'all',
            throttleType: 'debounce',
            throttleDelay: 300,
        },
        xAxis: {
            type: 'category',
            alignTicks: true,
            data: historyItems.map((item, index) => {

                return moment(item.time).format('HH:mm');
            }),

        },
        grid: {
            left: "10%",
            right: "5%",
            top: "6%",
            bottom: "6%"
        },
        yAxis: {
            type: 'value',
            minInterval: 1,
        },
        series: [
            {
                name: GlobalUtil.t("home.workerNumber"),
                type: 'line',
                data: historyItems.map((item) => item.hashrate),
                barWidth: '100%',
                smooth: true,
                showSymbol: false,
                areaStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: "rgba(47,101,255,0.33)"
                        },
                        {
                            offset: 1,
                            color: "rgba(47,101,255,0)"
                        }
                    ])
                },
            },
        ]
    };

    return <div style={{ width: "100%" }}>
        <div style={{ fontSize: "1rem", fontWeight: "500", textAlign: "center", color: StyleUtils.theme().fontColor_1 }}>{workerName} - {GlobalUtil.t("work.last24Hashrate")}</div>
        <EChartsReact
            showLoading={!expandWorker}
            option={option}
            style={{ height: "20rem" }}
        />
    </div>
}