import React, { useEffect, useState } from 'react';
import { Divider, Radio, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Account, ApiCode } from '../../../../data/common';
import { UserInfoManager } from '../../../../utils/UserInfoManager';
import Api from '../../../../utils/Api';
import events, { useEventBus } from '../../../../utils/events';
import { AppEvent } from '../../../../data/events';
import { StyleUtils } from '../../../../utils/Style';
import { AntdTable } from '../../../../views/commonview';
import { GlobalUtil } from '../../../../utils/GlobalText';




interface AccountProps {
  onSelectChange: (selectedRows: Account[]) => void
}

const AccountHideListView: React.FC<AccountProps> = (props) => {
  const [expandAccount, setExpandAccount] = useState<string[]>([])
  const [accounts, setAccounts] = useState<Account[]>([])
  const columns: ColumnsType<Account> = [
    {
      title: GlobalUtil.t("account.account"),
      dataIndex: 'account'
    },
    {
      title: GlobalUtil.t("account.remark"),
      dataIndex: 'desc',
    },
    {
      title: GlobalUtil.t("account.operation"),
      render: (data: Account) => <div style={{ display: "flex", flexDirection: "row" }}>
        <a onClick={(e) => {
          events.emit(AppEvent.DELETE_SUB_ACCOUNT, data.account)
        }}>{GlobalUtil.t("account.deleteAccount")}</a>
        <div style={{ width: StyleUtils.getWidth(20) }}></div>
        <a style={{}} onClick={() => {
          events.emit(AppEvent.UNHIDE_ACCOUNT, data.account)
        }}>{GlobalUtil.t("account.cancelHide")}</a>
      </div>
    },
  ];
  useEventBus(AppEvent.ON_ACCOUNT_INFO_UPDATE, (changeType: string) => {
    loadData()
  })
  let loadData = async () => {
    try {
      let res = await Api.getAccounts(true)
      if (res.code === ApiCode.SUCCESS) {
        setAccounts(UserInfoManager.transformAccount(res.data ?? []))
      }
    } catch (e) {

    }
  }
  useEffect(() => {
    loadData()
  }, [])
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: Account[]) => {
      props.onSelectChange(selectedRows)
    },
    getCheckboxProps: (record: Account) => ({
      disabled: record.isMain,
      name: record.account,
    }),
  };


  return (
    <div style={{ paddingTop: "1rem", }}>
      <AntdTable>
        <Table
          // bordered
          className={`${StyleUtils.isLight ? "tableD ckd" : "tableL ckl"} home-table`}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          rowKey={(item) => { return item.account }}
          columns={columns}
          dataSource={accounts.filter(v => {
            return v.hide
          })}
        />
      </AntdTable>
    </div>
  );
};

export default AccountHideListView;