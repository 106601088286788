import React, { useEffect, useState } from 'react';
import { Card, Layout, Menu, Row, Tabs, Tag } from 'antd';
import { UserOutlined, SafetyOutlined, UsergroupAddOutlined, SettingOutlined, DotChartOutlined } from '@ant-design/icons';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import AccountView from './views/AcoountView';
import SecurityView from './views/SecurityView';
import UserCenterView from './views/UserCenterView';
import TabReportView from './views/TabReportView';
import OnlyReadView from './views/OnlyReadView';
import { StyleUtils } from '../../utils/Style';
import { GlobalUtil } from '../../utils/GlobalText';
import { useEventBus } from '../../utils/events';
import { AppEvent } from '../../data/events';
import { AntdCard } from '../../views/commonview';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';

const { Header, Content } = Layout;

interface SettingButtonProp {
    title: string
    link: string
    select: string
    onClick: (key: string) => void

}
const SettingButton: React.FC<SettingButtonProp> = (props) => {
    let isSelect = props.select === props.link
    let navigator = useNavigate()
    return <Row justify={"center"} align={"middle"} style={{ paddingBottom: "1rem", cursor: "pointer", flexDirection: "column", position: "relative", }} onClick={() => {
        if (!isSelect) {
            props.onClick(props.link)
            navigator("/user/setting" + props.link)
        }
    }}>
        <div style={{ fontSize: "0.9rem", display: "flex", backgroundColor: "", color: isSelect ? StyleUtils.theme().mainButtonColor : StyleUtils.theme().fontColor_2 }}>{props.title}</div>
        <div style={{ position: "absolute", bottom: "0px", borderRadius: "56px 56px 0px 0px", background: isSelect ? StyleUtils.theme().mainButtonColor : "", width: "3rem", height: "0.2rem" }} />
    </Row>
}

const AccountSettings = () => {
    const { xs } = useBreakpoint()
    const SettingTab = [
        {
            "link": "#my-account",
            "title": GlobalUtil.t("setting.account"),
            "element": <AccountView />
        },
        {
            "link": "#tab-readonly-linker",
            "title": GlobalUtil.t("setting.onlyread"),
            "element": <OnlyReadView />
        },
        {
            "link": "#security-settings",
            "title": GlobalUtil.t("setting.security"),
            "element": <SecurityView />
        },
        {
            "link": "#personal-center",
            "title": GlobalUtil.t("setting.personal"),
            "element": <UserCenterView />
        },
        {
            "link": "#tab-report",
            "title": GlobalUtil.t("setting.tabreport"),
            "element": <TabReportView />
        }
    ]
    const location = useLocation();
    const [selectKey, setKey] = useState('#my-account')
    if (location.hash === "") {
        if (selectKey !== "#my-account") {
            setKey("#my-account")
        }
    } else if (selectKey !== location.hash) {
        setKey(location.hash)
    }
    return (
        <div style={{ paddingTop: StyleUtils.getWidth(36), paddingBottom: StyleUtils.getWidth(18), minHeight: window.innerHeight, }}>
            <AntdCard >
                <Card style={{ width: "100%" }}>
                    <Row align={"middle"} style={{ background: StyleUtils.theme().mainColor }}>
                        <div style={{ background: StyleUtils.theme().boardBg, marginBottom: StyleUtils.getWidth(1), width: "100%" }}>
                            {/* <div style={{ display: "flex", flexDirection: "row", background: "transparent", gap: xs ? "1rem" : "2.5rem" }} >
                                {
                                    SettingTab.flatMap((v, k) => {
                                        return <SettingButton key={k} onClick={(key) => {
                                            setKey(key)
                                        }} select={selectKey} title={v.title} link={v.link} />
                                    })
                                }
                            </div> */}
                            <Tabs
                                style={{color:StyleUtils.theme().fontColor_1}}
                                defaultActiveKey={"0"}
                                // onChange={(key) => {
                                //     console.log(key);

                                //     setKey(key)
                                // }}
                                items={SettingTab.map((v, k) => {
                                    return {
                                        label: v.title,
                                        key:String(k),
                                        children: <Row style={{ width: "100%" }} key={k}>
                                            {v.element}
                                        </Row>
                                    }
                                })}
                            />
                        </div>
                        {/* <div style={{ width: "100%" }} >
                            <Row style={{ width: "100%" }}>
                                {
                                    SettingTab.find((v, k) => {
                                        return v.link === location.hash || v.link === selectKey
                                    })?.element
                                }
                            </Row>
                        </div> */}
                    </Row>
                </Card>
            </AntdCard>
        </div>
    );
};

export default AccountSettings;
