import { Button, Input, Modal, message, Image, Checkbox } from 'antd';
import React, { useEffect, useState } from 'react';
import { ApiCode, GoogleBindInfo } from '../data/common';
import Api from '../utils/Api';
import { GlobalUtil } from '../utils/GlobalText';
import { StyleUtils } from '../utils/Style';
import events from '../utils/events';
import { AppEvent, AppMessageType } from '../data/events';
import { UserInfoManager } from '../utils/UserInfoManager';
import { AntdButton, AntdInput } from '../views/commonview';
import { isValidPwd } from '../utils/Utils';

export interface DoubleCheckProps {
    show: boolean
    onClick: (ok: boolean) => void
}

const ChangePwdView: React.FC<DoubleCheckProps> = (props) => {
    const [loading, setLoading] = useState(false)
    let [pwd, setPwd] = useState("")
    let [code, setCode] = useState("")
    let [confirmPwd, setConfirmPwd] = useState("")
    const disable = !pwd || !code || !confirmPwd || (confirmPwd !== pwd) //|| !emaliCode || !isSendCode 
    const clear = () => {
        setPwd("")
        setCode("")
        setConfirmPwd("")
    }
    return <Modal
        key={"changePwd"}
        width={"50%"}
        className='small_modal'
        maskClosable={false}
        centered={true}
        open={props.show}
        maskTransitionName=""
        onCancel={() => {
            clear()
            props.onClick(false)
        }}
        footer={[]}
    >
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", flexDirection: "row", color: StyleUtils.theme().fontColor_1 }}>{GlobalUtil.t("account.changePwd")}</div>
            <div style={{ marginTop: StyleUtils.getHeight(20) }}>
                <AntdInput>
                    <Input.Password
                        style={{}}
                        autoComplete={"off"}
                        aria-autocomplete='none'
                        value={code}
                        defaultValue={""}
                        placeholder={GlobalUtil.t("account.inputOldPwd")}
                        onChange={(e) => {
                            setCode(e.target.value.trim())
                        }}
                    />
                </AntdInput>
            </div>
            <div style={{ marginTop: StyleUtils.getHeight(20), position: 'relative' }}>
                {pwd && !isValidPwd(pwd) &&
                    <div style={{ fontSize: 12, color: "red", position: 'absolute', top: "-22px" }}>{GlobalUtil.t('register.pwdTip')}</div>
                }
                <AntdInput>
                    <Input.Password
                        value={pwd}
                        style={{}}
                        autoComplete={"off"}
                        defaultValue={""}
                        aria-autocomplete='none'
                        onChange={(e) => {
                            setPwd(e.target.value.trim())
                        }}
                        placeholder={GlobalUtil.t("account.inputNewPwd")}
                    />
                </AntdInput>
            </div>
            <div style={{ marginTop: StyleUtils.getHeight(20), position: 'relative' }}>
                {confirmPwd && pwd !== confirmPwd &&
                    <div style={{ fontSize: 12, color: "red", position: 'absolute', top: "-22px" }}>{GlobalUtil.t('register.pwdTip')}</div>
                }
                <AntdInput>
                    <Input.Password
                        value={confirmPwd}
                        style={{}}
                        autoComplete={"off"}
                        defaultValue={""}
                        aria-autocomplete='none'
                        onChange={(e) => {
                            setConfirmPwd(e.target.value.trim())
                        }}
                        placeholder={GlobalUtil.t("register.confirmPwd")}
                    />
                </AntdInput>
            </div>

            <div style={{ paddingTop: StyleUtils.getWidth(20), display: "flex", flexDirection: "row" }}>
                <AntdButton>
                    <Button
                        loading={loading} disabled={disable} onClick={async () => {
                            setLoading(true)
                            try {
                                let res = await Api.changePwd(code, pwd)
                                if (res.code === ApiCode.SUCCESS) {
                                    events.emit(AppEvent.SHOW_MESSAGE, {
                                        type: AppMessageType.SUCCESS,
                                        message: GlobalUtil.t("account.changeSuccTip")
                                    })
                                    clear()
                                    props.onClick(true)
                                    UserInfoManager.logout()
                                } else {
                                    events.emit(AppEvent.SHOW_MESSAGE, {
                                        type: AppMessageType.ERROR,
                                        message: res.msg ?? GlobalUtil.t("account.changeFailedTip")
                                    })
                                }
                            } catch (e) {
                                events.emit(AppEvent.SHOW_MESSAGE, {
                                    type: AppMessageType.ERROR,
                                    message: GlobalUtil.t("account.changeFailedTip")
                                })
                            }
                            setLoading(false)
                        }} type="primary" style={{ width: "100%" }}>{GlobalUtil.t("account.confirmModif")}</Button>
                </AntdButton>
            </div>

        </div>
    </Modal>
}


export default ChangePwdView;