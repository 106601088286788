import React, { useState } from 'react';
import { Account } from '../../../data/common';
import { AppEvent } from '../../../data/events';
import Api from '../../../utils/Api';
import events from '../../../utils/events';
import { GlobalUtil } from '../../../utils/GlobalText';
import { UserInfoManager } from '../../../utils/UserInfoManager';
import DoubleCheckView from '../../../modal/DoubleCheckView';
import { StyleUtils } from '../../../utils/Style';
import { Col, Row } from 'antd';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';


interface SecurityData {
    key: string
    title: string
    desc: string
    status: Boolean | null,
    rightIcon: string
}

const SecurityItem = (data: SecurityData, onClick: () => void) => {
    return (
        <Row justify={"space-between"} align={"middle"} style={{
            borderRadius: "8px",
            background: StyleUtils.theme().mainColor,
            width: "100%",
            padding: "1rem",

            flexFlow: "row nowrap",
            columnGap: "1rem"
        }}>
            <Row style={{ flexDirection: "row", gap: ".5rem", flex: "1 1 55%" }}>

                <Row style={{ flexDirection: "column", rowGap: ".75rem" }}>
                    <div style={{ fontSize: "0.9rem", fontWeight: "500", color: StyleUtils.theme().fontColor_1 }}>
                        {data.title}
                    </div>
                    <div style={{ fontSize: "0.8rem", display: "flex", color: StyleUtils.theme().fontColor_3 }}>
                        {data.desc}
                    </div>
                </Row>
            </Row>
            <Row align={"middle"} style={{ display: 'grid', gap: ".5rem", justifyItems: 'center', gridTemplateColumns: '2fr 1fr' }}>
                {data.key !== "delete" ? <div style={{
                    border: "solid",
                    color: data.status ? "#61C0BE" : "#F27D71",
                    borderColor: data.status ? "#61C0BE" : "#F27D71",
                    borderWidth: StyleUtils.getWidth(1),
                    borderRadius: "8px",
                    fontSize: "0.9rem",
                    padding: "0 12px",
                }}>
                    {data.status ? GlobalUtil.t("security.setTip") : GlobalUtil.t("security.unsetTip")}
                </div> : <div style={{
                    padding: "0 12px", borderWidth: StyleUtils.getWidth(1),
                    borderRadius: "8px",
                    fontSize: "0.9rem",

                }} ></div>}
                <div onClick={onClick}
                    style={{
                        cursor: "pointer",
                        fontSize: "0.9rem",
                        color: StyleUtils.theme().mainButtonColor
                    }}
                >
                    {data.rightIcon}
                </div>
            </Row>
            {/* <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <div style={{ fontSize: "1rem", color: StyleUtils.theme().fontColor_1 }}>
                    {data.title}
                </div>
                <div style={{
                    border: "solid",
                    color: data.status ? "#61C0BE" : "#F27D71",
                    borderColor: data.status ? "#61C0BE" : "#F27D71",
                    borderWidth: StyleUtils.getWidth(1),
                    borderRadius: StyleUtils.getWidth(2),
                    fontSize: "1rem",
                    marginLeft: StyleUtils.getWidth(5),
                    padding: "1px 1px 1px 1"
                }}>
                    {data.status ? GlobalUtil.t("已设置") : GlobalUtil.t("未开启")}
                </div>
            </div>
            <div style={{ flexDirection: "row", display: "flex", maxWidth: "75%", marginTop: StyleUtils.getHeight(2) }}>
                <div style={{ fontSize: "0.88rem", color: StyleUtils.theme().fontColor_3 }}>
                    {data.desc}
                </div>
            </div>
            <div onClick={onClick}
                style={{

                    cursor: "pointer",
                    fontSize: "1rem",
                    color: StyleUtils.theme().mainButtonColor
                }}
            >
                {data.rightIcon}
            </div> */}
        </ Row>
    )
}
const SecurityView = () => {
    let [mainAccount, setMainAccount] = useState<Account | null>(UserInfoManager.mainAccount)
    const Items: SecurityData[] = [
        {
            "key": "pwd",
            "title": GlobalUtil.t("security.pwdTitle"),
            "desc": GlobalUtil.t("security.pwdDesc"),
            "status": true,
            "rightIcon": GlobalUtil.t("security.edit"),
        },
        {
            "key": "check",
            "title": GlobalUtil.t("security.checkTitle"),
            "desc": GlobalUtil.t("security.checkDesc"),
            "status": mainAccount?.isTwofactorOpen ?? false,
            "rightIcon": mainAccount?.isTwofactorOpen ? GlobalUtil.t("security.close") : GlobalUtil.t("security.open")
        },
        {
            "key": "delete",
            "title": GlobalUtil.t("security.deleteAccount"),
            "desc": GlobalUtil.t("security.deleteDesc"),
            "status": null,
            "rightIcon": GlobalUtil.t("security.delete"),
        }
    ]
    events.on(AppEvent.UPDATE_USERINFO, () => {
        setMainAccount(UserInfoManager.mainAccount)
    })
    return <div style={{ background: StyleUtils.theme().boardBg, paddingTop: "1rem", width: "100%" }}>
        <div style={{ flexDirection: "column", display: "flex", width: "100%", rowGap: ".5rem", }}>
            {
                Items.flatMap((v, index) => {
                    return (
                        <div key={v.title}
                            style={{
                                width: "100%",
                            }}
                        >
                            {SecurityItem(v, () => {

                                if (v.key === "check") {
                                    if (!mainAccount?.isTwofactorOpen) {
                                        events.emit(AppEvent.SHOW_DOUBLE_CHECC)
                                    } else {
                                        events.emit(AppEvent.CLOSE_DOUBLE_CHECC)
                                    }
                                } else if (v.key === "pwd") {
                                    if (!mainAccount?.isTwofactorOpen) {
                                        events.emit(AppEvent.SHOW_TIP_DOUBLE_CHECC)
                                    } else {
                                        //
                                        events.emit(AppEvent.CHANGE_PWD)
                                    }
                                } else if (v.key === "delete") {
                                    if (!mainAccount?.isTwofactorOpen) {
                                        events.emit(AppEvent.SHOW_TIP_DOUBLE_CHECC)
                                    } else {
                                        events.emit(AppEvent.DELETE_MAIN_ACCOUNT)
                                    }
                                }
                            })}
                            {(index !== Items.length - 1) &&
                                <div style={{ width: "2%", height: StyleUtils.getHeight(1) }} />
                            }
                        </div>
                    )
                })

            }
        </div>
    </div>
}

export default SecurityView