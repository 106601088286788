import { Button, Card, Dropdown, QRCode, Space, Table, Tag, Input, ConfigProvider, Row, Col } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { useEffect, useState } from "react";
import { ApiCode, OnlyReadData } from "../../../data/common";
import AddOrUpdateOnlyReadModel from "../../../modal/AddOnlyReadModel";
import { SearchOutlined, SettingOutlined, EditOutlined, CopyOutlined, QrcodeOutlined, DeleteOutlined } from '@ant-design/icons';
import Api from "../../../utils/Api";
import events from "../../../utils/events";
import { AppEvent, AppMessageType } from "../../../data/events";
import { StyleUtils } from "../../../utils/Style";
import { UserInfoManager } from "../../../utils/UserInfoManager";
import { AntdCard, AntdInput, AntdTable, AppButton } from "../../../views/commonview";
import { GlobalUtil } from "../../../utils/GlobalText";
import CustomInput from "../../../components/CustomInput";
import { MobileReadOnlyList } from "./MobileReadOnlyList";
import { Mobile, Pc } from "../../../components";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import { DeleteReadOnly } from "../../../modal/DeleteReadOnlyModel";



export class OnlyDataAction {
    constructor(show: boolean = false, data: OnlyReadData | null = null) {
        this.show = show
        this.data = data
    }
    show: boolean = false
    data: OnlyReadData | null = null
}

const OnlyReadView = () => {
    const [updateIndex, setUpdateIndex] = useState(false)
    const { xs } = useBreakpoint()
    const [onlyDataAction, setOnlyDataAction] = useState<OnlyDataAction | null>()
    const [data, setData] = useState<OnlyReadData[]>([])
    const [settingOpen, setSettingOpen] = useState<OnlyReadData | null>(null)
    const [selectData, setSelectData] = useState<OnlyReadData[] | null>(null)
    const [search, setSearch] = useState("")
    const [openDM, setOpenDM] = useState(false)
    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: OnlyReadData[]) => {
            setSelectData(selectedRows)
        },
        getCheckboxProps: (record: OnlyReadData) => ({
            disabled: false,
            name: record.id,
        }),
    };
    const loadData = async (refresh: boolean = false) => {
        try {

            let res = await Api.getOnlyRead()
            if (res.code === ApiCode.SUCCESS) {
                let result = res.data ?? []
                setData(result)
            }
        } catch (e) {

        }

    }
    const deleteOnlyRead = async (id: string) => {
        try {
            let res = await Api.deleteOnlyRead(id)
            if (res.code === ApiCode.SUCCESS) {
                events.emit(AppEvent.SHOW_MESSAGE, {
                    type: AppMessageType.SUCCESS,
                    message: GlobalUtil.t("deleteSuccessTip")
                })
                setSelectData(null)
                setOpenDM(false)
                setUpdateIndex(!updateIndex)
            } else {
                events.emit(AppEvent.SHOW_MESSAGE, {
                    type: AppMessageType.ERROR,
                    message: res.msg ?? GlobalUtil.t("deleteErrorTip")
                })
            }
        } catch (e) {
            events.emit(AppEvent.SHOW_MESSAGE, {
                type: AppMessageType.ERROR,
                message: GlobalUtil.t("deleteErrorTip")
            })
        }
    }

    const filterData = (): OnlyReadData[] => {
        if (search) {
            return data.filter((value) => {
                let accountIndex = value.accounts.indexOf(search)
                let nameIndex = value.name.indexOf(search)
                let permissionIndex = value.accessPermission.indexOf(search)
                let currencyIndex = value.currencies.indexOf(search)
                if (accountIndex >= 0 || nameIndex >= 0 || permissionIndex >= 0 || currencyIndex >= 0) {
                    return true
                }
                return false
            })
        }
        return data
    }
    const handleClickOk = () => {

        let id = ""
        selectData?.forEach((v) => {
            if (id.length) {
                id += ","
            }
            id += v.id
        })
        deleteOnlyRead(id)
    }
    const handleClickCancel = () => {
        setOpenDM(false)
    }
    useEffect(() => {
        UserInfoManager.getAccountInfo()
    }, [])
    useEffect(() => {
        loadData()
    }, [updateIndex])
    const columns: ColumnsType<OnlyReadData> = [
        {
            title: GlobalUtil.t("onlyread.remark"),
            dataIndex: 'name',
            width: 200,
            // ellipsis: true,
            render: (value: number) => {
                return <div style={{ whiteSpace: 'pre-wrap', fontSize: "0.9rem", color: StyleUtils.theme().fontColor_1, fontWeight: "400" }}>
                    {value}
                </div>
            }
        },
        {
            title: GlobalUtil.t("onlyread.permission"),
            dataIndex: 'accessPermission',
            // width: "auto",
            width: 150,
            render(value, record, index) {
                let permissions = record.accessPermission.split(",") as string[]
                const PERMISSION_1 = GlobalUtil.t("onlyread.permission_1")
                const PERMISSION_2 = GlobalUtil.t("onlyread.permission_2")
                const PERMISSION_3 = GlobalUtil.t("onlyread.permission_3")
                return <Space style={{}} size={[2, 4]} wrap>
                    {permissions.flatMap((v, k) => {
                        let p = v
                        if (v === "1") {
                            p = PERMISSION_1
                        } else if (v === "2") {
                            p = PERMISSION_2
                        } else if (v === "3") {
                            p = PERMISSION_3
                        }
                        return <Tag key={k} style={{ fontSize: "0.9rem", borderColor: StyleUtils.theme().mainButtonColor, background: StyleUtils.theme().boardB2, color: StyleUtils.theme().fontColor_1 }}>{p}</Tag>
                    })}
                </Space>
            },
        },
        {
            title: GlobalUtil.t("onlyread.account"),
            width: 200,
            dataIndex: "accounts",
            ellipsis: true,
            render: (value: string) => {
                return <div style={{ width: '100%', fontSize: "0.9rem", display: "flex", flexDirection: "column", whiteSpace: "pre-wrap", rowGap: ".5rem", color: StyleUtils.theme().fontColor_1, fontWeight: "400" }}>
                    {value.split(",").flatMap((item, indev) => {
                        return (
                            <Tag key={item}
                                // color="green"
                                style={{
                                    whiteSpace: "pre-wrap",
                                    wordBreak: 'break-all',
                                    width: '100%',
                                    fontSize: "0.9rem",
                                    textAlign: 'center'
                                }}
                            >
                                {item}
                            </Tag>
                        )
                    })}
                </div>
            }

        },
        {
            title: GlobalUtil.t("onlyread.currency"),
            width: 100,
            dataIndex: "currencies",
            render: (value: string) => {
                return <div style={{ fontSize: "0.9rem", color: StyleUtils.theme().fontColor_1, fontWeight: "400", display: 'flex', flexDirection: 'column', rowGap: ".5rem", }}>
                    {value.split(",").flatMap((item, indev) => {
                        return (
                            <Tag color="purple"
                                key={item}
                                style={{
                                    fontSize: "0.9rem",
                                    textAlign: 'center'
                                }}
                            >{item}
                            </Tag>
                        )
                    })}
                </div>
            }

        },
        {
            title: GlobalUtil.t("onlyread.link"),
            dataIndex: "url",
            render(value, record, index) {
                return <div style={{ borderRadius: StyleUtils.getWidth(5), border: "1px solid #E6E6E6", padding: "2px 7px 2px 7px", width: "100%", ...StyleUtils.textWrap() }} >{value}</div>
            },

        },
        {
            title: GlobalUtil.t("onlyread.operation"),
            width: StyleUtils.getWidth(50),
            align: "center",
            key: "action",
            render(value, record, index) {
                return (
                    <Dropdown
                        open={settingOpen?.id === record.id}
                        placement={"bottomCenter"}
                        trigger={["click"]}
                        onOpenChange={(open) => {
                            if (xs) return
                            if (open) {
                                setSettingOpen(record)
                            } else {
                                setSettingOpen(null)
                            }
                        }}
                        autoFocus
                        dropdownRender={(props) => {
                            return <div
                                onMouseDown={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                }}
                                style={{ boxShadow: '1px 2px 9px ' + StyleUtils.theme().boardB2, background: StyleUtils.theme().boardBg, display: "flex", flexDirection: "row", borderRadius: StyleUtils.getWidth(6) }}>
                                <EditOutlined onClick={(e) => {
                                    setSettingOpen(null)
                                    setOnlyDataAction(new OnlyDataAction(true, record))
                                    e.preventDefault()
                                }} style={{ width: "50px", height: "50px", display: "flex", justifyContent: "center", alignItems: "center" }} />
                                <CopyOutlined onClick={(e) => {
                                    navigator.clipboard.writeText(record.url ?? "")
                                    setSettingOpen(null)
                                    e.preventDefault()
                                }} style={{ width: "50px", height: "50px", display: "flex", justifyContent: "center", alignItems: "center" }} />
                                <Dropdown
                                    placement={"bottomCenter"}
                                    dropdownRender={() => {
                                        return <QRCode value={record.url} />;
                                    }}
                                >
                                    <QrcodeOutlined onClick={(e) => {
                                        setSettingOpen(null)
                                        e.preventDefault()
                                    }} style={{ width: "50px", height: "50px", display: "flex", justifyContent: "center", alignItems: "center" }} />
                                </Dropdown>
                                <DeleteOutlined onClick={(e) => {
                                    setSettingOpen(null)
                                    deleteOnlyRead(record.id)
                                    e.preventDefault()
                                }} style={{ width: "50px", height: "50px", display: "flex", justifyContent: "center", alignItems: "center" }} />
                            </div>
                        }}
                    >
                        <SettingOutlined style={{ color: StyleUtils.theme().mainButtonColor }} />
                    </Dropdown>
                )
            },
        },
    ];

    return <div style={{ background: StyleUtils.theme().boardBg, paddingTop: "1rem", width: "100%" }}>
        <AddOrUpdateOnlyReadModel data={onlyDataAction?.data} onClick={(ok) => {
            if (ok) {
                setUpdateIndex(!updateIndex)
            }
            setOnlyDataAction(new OnlyDataAction())
        }} show={onlyDataAction?.show === true} />
        <DeleteReadOnly show={openDM} handleClickOk={handleClickOk} handleClickCancel={handleClickCancel} />
        <Row justify={"space-between"} style={{ gap: ".5rem", marginBottom: "1rem" }}>

            <Row style={{ columnGap: ".5rem" }}>
                <AppButton onClick={() => {
                    setOnlyDataAction(new OnlyDataAction(true))
                }} text={GlobalUtil.t("onlyread.create")} />
                <AppButton onClick={() => {
                    setOpenDM(true)

                }} disabled={!selectData?.length} text={GlobalUtil.t("onlyread.delete")} />
            </Row>

            <AntdInput>
                <Input
                    autoComplete="off"
                    style={{
                        fontSize: "0.88rem",
                        display: "flex",
                        flex: "auto",
                        maxWidth: "300px",
                        borderRadius: "8px",
                        textOverflow:'ellipsis',
                        border: `1px solid ${StyleUtils.theme().mainColor}`,
                        boxShadow: "none",
                    }}
                    placeholder={GlobalUtil.t("onlyread.searchTip")}
                    suffix={
                        <SearchOutlined style={{ color: "#899199" }} />
                    }
                    onChange={(e) => {
                        setSearch(e.target.value.trim())
                    }}
                />
            </AntdInput>

        </Row>
        <Pc>
            <AntdTable>
                <Table
                    // bordered
                    className={`${StyleUtils.isLight ? "tableD ckd" : "tableL ckl"} home-table`}

                    rowSelection={{
                        type: "checkbox",
                        ...rowSelection,
                    }}
                    rowKey={(item) => { return item.id }}
                    columns={columns}
                    dataSource={filterData()}>
                </Table>
            </AntdTable>
        </Pc>
        <Mobile>
            <MobileReadOnlyList
                data={filterData()}
                setSelectData={setSelectData}
                deleteOnlyRead={deleteOnlyRead}
                settingOpen={settingOpen}
                setSettingOpen={setSettingOpen}
                setOnlyDataAction={setOnlyDataAction} />
        </Mobile>
    </div>
}


export default OnlyReadView