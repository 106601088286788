import { useEffect, useState } from "react"
import { AppEvent } from "../../data/events"
import events from "../../utils/events"
import { ServerIncomeInfo } from "../../data/common"
import Api from "../../utils/Api"
import Table, { ColumnsType, TablePaginationConfig } from "antd/es/table"
import moment from "moment"
import { StyleUtils } from "../../utils/Style"
import { AntdTable } from "../../views/commonview"
import { WorkInfoManager } from "../../utils/WorkData"
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint"
import { GlobalUtil } from "../../utils/GlobalText"


class IncomeTableProps {
    shareUrl: string = ""
}

export const IncomeTableView: React.FC<IncomeTableProps> = (props) => {

    const [update, setUpdate] = useState(false)
    events.on(AppEvent.REFRES, () => {
        setUpdate(!update)
    })
    const { xs } = useBreakpoint()
    const [data, setData] = useState<ServerIncomeInfo[]>([])
    const [tableParams, setTableParams] = useState<TablePaginationConfig>({
        current: 1,
        pageSize: 10,
    });

    const loadData = async () => {
        try {
            let res = await Api.getIncomeList(props.shareUrl, tableParams?.current, tableParams?.pageSize)
            if (res.code) {
                setTableParams({
                    ...tableParams,
                    total: res.data?.total,
                });
                setData(res.data?.records ?? [])
            }
        } catch (error) {

        }

    }
    const handleTableChange = (
        pagination: TablePaginationConfig,
    ) => {
        setTableParams(pagination);

        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams?.pageSize) {
            setData([]);
        }
    };
    useEffect(() => {
        loadData();
    }, [JSON.stringify(tableParams)]);
    useEffect(() => {
        loadData()
    }, [update])
    const columns: ColumnsType<ServerIncomeInfo> = [
        {
            title: GlobalUtil.t("income.time"),
            dataIndex: 'settleDate',
            render: (value: number) => {
                return <div style={{ fontSize: "0.88rem", color: StyleUtils.theme().fontColor_1, fontWeight: "400" }}>
                    {moment(value).format('YYYY-MM-DD HH:mm:ss')}
                </div>
            }
        },
        {
            title: GlobalUtil.t("income.dayOfhasrate"),
            dataIndex: 'hashrateOfDay',
            render: (value: number) => {
                return <div style={{ fontSize: "0.88rem", color: StyleUtils.theme().fontColor_1, fontWeight: "400" }}>
                    {value}
                </div>
            }
        },
        {
            title: GlobalUtil.t("income.money"),
            dataIndex: "amount",
            render: (value: number) => {
                return <div style={{ fontSize: "0.88rem", color: StyleUtils.theme().fontColor_1, fontWeight: "400" }}>
                    {/* 小屏时指定保留小数点后几位数，切不会四舍五入，特别在金融数字时需要使用改方法，使用toFixed会进位 */}
                    {xs && value ? value.toLocaleString(undefined, { minimumFractionDigits: 4 }).replace(/\.?0+$/, '') : value}
                </div>
            }

        },
        {
            title: GlobalUtil.t("income.desc"),
            dataIndex: "description",
            render: (value: number) => {
                return <div style={{ fontSize: "0.88rem", color: StyleUtils.theme().fontColor_1, fontWeight: "400" }}>
                    {value}
                </div>
            }

        }
    ];
    return <div style={{ width: "100%", paddingTop: WorkInfoManager.shareUrl ? "1rem" : "", background: StyleUtils.theme().boardBg }}>
        <AntdTable
        >
            <Table
                pagination={tableParams}
                onChange={handleTableChange}
                className={`inctab ${StyleUtils.isLight ? "tableD" : "tableL"}`}
                style={{ borderRadius: WorkInfoManager.shareUrl ? "0" : "", marginLeft: WorkInfoManager.shareUrl ? StyleUtils.getWidth(12) : 0, marginRight: WorkInfoManager.shareUrl ? StyleUtils.getWidth(12) : 0 }}
                columns={columns}
                dataSource={data}
            />
        </AntdTable>
    </div>
}
