import { Button, Input, Modal, message, Image, Checkbox } from 'antd';
import React, { useEffect, useState } from 'react';
import { ApiCode, GoogleBindInfo } from '../data/common';
import Api from '../utils/Api';
import { GlobalUtil } from '../utils/GlobalText';
import { StyleUtils } from '../utils/Style';
import events from '../utils/events';
import { AppEvent, AppMessageType } from '../data/events';
import { UserInfoManager } from '../utils/UserInfoManager';
import { AntdButton, AntdInput } from '../views/commonview';

export interface DoubleCheckProps {
    show: boolean
    onClick: (ok: boolean) => void
}

const DeleteAccountView: React.FC<DoubleCheckProps> = (props) => {
    const [loading, setLoading] = useState(false)
    let [code, setCode] = useState("")
    const disable = !code
    const clear = () => {
        setCode("")
    }
    return <Modal
        width={"50%"}
        className='small_modal'
        maskClosable={false}
        centered={true}
        open={props.show}
        maskTransitionName=""
        onCancel={() => {
            clear()
            props.onClick(false)
        }}
        footer={[]}
    >
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", flexDirection: "row", color: StyleUtils.theme().fontColor_1 }}>{GlobalUtil.t("account.deleteMainCount")}</div>
            <div style={{ marginTop: StyleUtils.getHeight(15), color: StyleUtils.theme().fontColor_1 }}>{GlobalUtil.t('checkCode')}</div>
            <div style={{ marginTop: StyleUtils.getHeight(8) }}>
                <AntdInput>
                    <Input
                        style={{}}
                        value={code}
                        placeholder={"000000"}
                        onChange={(e) => {
                            setCode(e.target.value.trim())
                        }}
                    />
                </AntdInput>
            </div>
            <div style={{ paddingTop: StyleUtils.getWidth(20), display: "flex", flexDirection: "row" }}>
                <AntdButton>
                    <Button
                        loading={loading} disabled={disable} onClick={async () => {
                            setLoading(true)
                            try {
                                let res = await Api.deleteAccount(code)
                                if (res.code === ApiCode.SUCCESS) {
                                    events.emit(AppEvent.SHOW_MESSAGE, {
                                        type: AppMessageType.SUCCESS,
                                        message: GlobalUtil.t("successTip")
                                    })
                                    UserInfoManager.logout(false)
                                    clear()
                                    props.onClick(true)
                                } else {
                                    events.emit(AppEvent.SHOW_MESSAGE, {
                                        type: AppMessageType.ERROR,
                                        message: res.msg ?? GlobalUtil.t("errorTip")
                                    })
                                }
                            } catch (e) {
                                events.emit(AppEvent.SHOW_MESSAGE, {
                                    type: AppMessageType.ERROR,
                                    message: GlobalUtil.t("errorTip")
                                })
                            }
                            setLoading(false)
                        }} type="primary" style={{ width: "100%" }}>{GlobalUtil.t("delete")}</Button>
                </AntdButton>
            </div>

        </div>
    </Modal>
}


export default DeleteAccountView;