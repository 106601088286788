import { ServerWorkerHashrateDistribution, ServerWorkerState } from "../data/common";
import { ApiCode, ServerWorkerInfoResult } from "../data/common";
import { AppEvent } from "../data/events";
import { WorkHashrateListReq } from "../data/request";
import Api from "./Api";
import { UserInfoManager } from "./UserInfoManager";
import events from "./events";

class IWorkInfoManager {
    private _groupData: ServerWorkerInfoResult | null = null
    private _workState?: ServerWorkerState
    private _hashrateData: ServerWorkerHashrateDistribution | null = null
    private req: WorkHashrateListReq = new WorkHashrateListReq()
    groupId = "0"
    mode = "2" //1实时，2.1天
    status = "0"  //0 实时  1 在线  2 离线  3 失效
    loopHasrate: any = null
    shareUrl: string = ""
    reset = () => {
        this.req = new WorkHashrateListReq()
        this.groupId = "0"
        this.mode = "2"
        this.status = "0"
    }

    init = (shareUrl: string = "") => {
        this.shareUrl = shareUrl
        this.loadGroupData()
    }

    loadGroupData = async () => {
        if (!UserInfoManager.currentAccount && !this.shareUrl) {
            events.emit(AppEvent.GROUP_DATA_CHANGE)
            return
        }
        if (!this.shareUrl) {
            this.loadWorkState()
        }
        this.req.groupId = this.groupId
        this.req.workerStatus = this.status

        try {
            let res = await Api.getWorkHashrateList(this.shareUrl ?? "", this.req)
            if (res.code === ApiCode.SUCCESS) {
                this._groupData = res.data ?? null
            } else {
                this._groupData = null
            }

        } catch (e) {
            console.log(e);     
        }
        events.emit(AppEvent.GROUP_DATA_CHANGE)

    }

    loadWorkState = async () => {
        try {
            let res = await Api.getWorkState(this.shareUrl)
            if (res.code === ApiCode.SUCCESS) {
                this._workState = res.data ?? undefined
            }

        } catch (e) {
        }
    }

    updateSearch = (search: string) => {
        this.req.searchWorker = search.trim()
        this.loadGroupData()
    }
    updateSize = (currentPage: number, pageSize: number) => {
        this.req.current = currentPage
        this.req.size = pageSize
        this.loadGroupData()
    }
    updateHasrate = (min: number, max: number) => {
        this.loadGroupData()
    }
    updateStatus = (status: string) => {
        this.status = status
        this.loadGroupData()
    }
    updateGroupId = (groupId: string) => {
        this.groupId = groupId
        this.loadGroupData()
    }
    updateMode = (mode: string) => {
        this.mode = mode
    }
    getGroupData = (): ServerWorkerInfoResult | null => {
        return this._groupData
    }

    getHashrateData = (): ServerWorkerHashrateDistribution | null => {
        return this._hashrateData
    }
    getWorkState = (): ServerWorkerState | undefined => {
        return this._workState
    }

}

const WorkInfoManager = new IWorkInfoManager()

export { WorkInfoManager, IWorkInfoManager };
