import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import ListPage from "../../../views/List2";
import Api from "../../../utils/Api";
import { Card, Carousel, List, Radio, Table } from "antd";
import EChartsReact from "echarts-for-react";
import { ApiCode, HomeHashrateHistory, HomeUserInfoHistory, ServerHomeHistory } from "../../../data/common";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import * as echarts from 'echarts';

import moment from "moment";
import { UserInfoManager } from "../../../utils/UserInfoManager";
import events from "../../../utils/events";
import { AppEvent } from "../../../data/events";
import { StyleUtils } from "../../../utils/Style";
import { GlobalUtil } from "../../../utils/GlobalText";
import { WorkInfoManager } from "../../../utils/WorkData";

class HashrateData {
  data: ServerHomeHistory[] = []
  changeMode: boolean = false
}
const WorkAccountTableView: React.FC = () => {
  const [update, setUpdate] = useState(false)
  const [mode, setMode] = useState(1)
  const [duration, setDuration] = useState(1)

  const [data, setData] = useState<ServerHomeHistory[]>([])
  const [changeMode, setChangeMode] = useState<boolean>(false)
  const loadData = async (changeMode: boolean = false) => {
    let account = UserInfoManager.currentAccount

    if (WorkInfoManager.shareUrl) {
      account = UserInfoManager.shareAccount
    }
    let res = await Api.getUserHashrateHistory(WorkInfoManager.shareUrl, duration, mode === 1 ? account : "")
    if (res.code === ApiCode.SUCCESS) {
      let thisData = new HashrateData()
      thisData.data = res.data ?? []
      setChangeMode(changeMode)
      setData(thisData.data)
    }
  }

  events.on(AppEvent.REFRES, () => {
    setUpdate(!update)
  })
  useEffect(() => {
    // console.log("load with mode")
    if (UserInfoManager.currentAccount !== "") {
      loadData(true)
    }
  }, [mode])
  useEffect(() => {
    // console.log("load with update")
    if (UserInfoManager.currentAccount !== "") {
      loadData()
    }
  }, [update, duration])

  const option = {
    color: [StyleUtils.theme().echart_line_color_1],
    textStyle: {
      color: StyleUtils.theme().fontColor_1
    },
    tooltip: {
      trigger: 'axis'
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    legend: {
      textStyle: {
        color: StyleUtils.theme().fontColor_1
      },
      data: data.flatMap((v, i) => {
        // TODO: 最先遍历的数据色调在最下面，下面一行代码是确保蓝色的渐变色在上面显示
        // 但是如果数据中最好一组数据为0或者值很低，效果不是很理想，还待研究
        // let colorIndex = data && data.length - 1 -i
        const index = i % StyleUtils.EAreaColor.length
        return {
          name: v.account,
          itemStyle: {
            color: StyleUtils.EAreaColor[index][0]
          }
        }
      })
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        data: data.length && data[0]?.values?.flatMap((v) => {
          if (duration === 1) {
            return moment(v.time).format('HH:mm');
          }
          return moment(v.time).format('MM-DD');
        }),
      }
    ],
    yAxis: [
      {
        type: 'value'
      }
    ],
    series: data.flatMap((v, i) => {
      let hasData = false
      // let colorIndex = data && data.length - 1 -i
      v.values.forEach((vValue) => {
        if (parseFloat(vValue.hashrate) > 0) {
          hasData = true
        }
      })
      const index = i % StyleUtils.EAreaColor.length
      return {
        name: v.account,
        type: 'line',
        smooth: true,
        showSymbol: false,
        lineStyle: {
          width: hasData ? 2 : 0,
          color: StyleUtils.EAreaColor[index][0]
        },
        emphasis: {
          focus: 'series'
        },
        color: StyleUtils.EAreaColor[index][0],
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: StyleUtils.EAreaColor[index][1]
            },
            {
              offset: 1,
              color: StyleUtils.EAreaColor[index][2]
            }
          ])
        },
        data: v.values.flatMap((v) => {
          return (parseFloat(v.hashrate))
        })
      }
    })
  };
  let selectCurrent = mode === 1
  let select30Min = duration === 1
  return (
    <Card style={{ borderColor: StyleUtils.theme().boardBg, background: StyleUtils.theme().boardBg }}>
      <div style={{ position: "relative", width: "100%", display: "flex", flexFlow: "row wrap", justifyContent: 'space-between', alignItems: "center", gap: "10px" }}>
        <div style={{ color: StyleUtils.theme().fontColor_2, fontWeight: "500", fontSize: StyleUtils.getFont(16) }}>{UserInfoManager.getShowCurrency()} - {select30Min ? GlobalUtil.t("work.last24Hashrate") : GlobalUtil.t("work.days30Hashrate")}</div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div style={{ display: "flex", flexDirection: "row", background: StyleUtils.theme().mainColor, borderRadius: StyleUtils.getWidth(5) }}>
            <div style={{ padding: "8px 12px", cursor: "pointer", background: (!selectCurrent) ? "" : StyleUtils.theme().mainButtonColor, borderRadius: StyleUtils.getWidth(5), color: !selectCurrent ? StyleUtils.theme().fontColor_1 : "white", fontSize: "0.88rem", alignItems: "center", justifyContent: "center", display: "flex", fontWeight: "500" }} onClick={() => {
              setMode(1)
            }}>{GlobalUtil.t("work.currentAccount")}</div>
            <div style={{ padding: "8px 12px", cursor: "pointer", background: (selectCurrent) ? "" : StyleUtils.theme().mainButtonColor, borderRadius: StyleUtils.getWidth(5), color: selectCurrent ? StyleUtils.theme().fontColor_1 : "white", fontSize: "0.88rem", alignItems: "center", justifyContent: "center", display: "flex", fontWeight: "500" }} onClick={() => {
              setMode(2)
            }}>{GlobalUtil.t("work.allAccount")}</div>
          </div>
          <div style={{ marginLeft: "1rem", display: "flex", flexDirection: "row", background: StyleUtils.theme().mainColor, borderRadius: StyleUtils.getWidth(5) }}>
            <div style={{ padding: "8px 12px", cursor: "pointer", background: (!select30Min) ? "" : StyleUtils.theme().mainButtonColor, borderRadius: StyleUtils.getWidth(5), color: !select30Min ? StyleUtils.theme().fontColor_1 : "white", fontSize: "0.88rem", alignItems: "center", justifyContent: "center", display: "flex", fontWeight: "500" }} onClick={() => {
              setDuration(1)
            }}>{GlobalUtil.t("work.24Hours")}</div>
            <div style={{ padding: "8px 12px", cursor: "pointer", background: (select30Min) ? "" : StyleUtils.theme().mainButtonColor, borderRadius: StyleUtils.getWidth(5), color: select30Min ? StyleUtils.theme().fontColor_1 : "white", fontSize: "0.88rem", alignItems: "center", justifyContent: "center", display: "flex", fontWeight: "500" }} onClick={() => {
              setDuration(2)
            }}>{GlobalUtil.t("work.30Days")}</div>
          </div>
        </div>
      </div>
      <div>
        <EChartsReact
          notMerge={changeMode}
          option={option}
          // theme={"dark"}
          style={{ height: "24rem", marginTop: "1rem" }}
        />
      </div>
    </Card >
  );
};

export default WorkAccountTableView;
