import events from "./events"
import { Account, ApiCode, Currency, ServerAccountListInfo, ServerAccountListInfoWithHashrate } from "../data/common"
import Api from "./Api"
import { AppEvent } from "../data/events"
import WorkManagerPage from "../page/work/WorkManagerPage"
import { GlobalUtil } from "./GlobalText"
import { WorkInfoManager } from "./WorkData"

class IUserInfoManager {
    currentAccount = "" //当前用户
    language = ""
    currency = ""  //当前币种
    _login = false
    _refreshListeners = []
    accounts: Account[] = []
    sourceAccountsWithHashrate: ServerAccountListInfoWithHashrate[] = []
    accountsWithHashrate: ServerAccountListInfoWithHashrate[] = []
    sourceShareAccountsWithHashrate: ServerAccountListInfoWithHashrate[] = []
    shareAccountsWithHashrate: ServerAccountListInfoWithHashrate[] = []

    mainAccount: Account | null = null
    currencyList: Currency[] = []
    shareCurrencyList: Currency[] = []

    //share
    shareAccount = ""
    shareAuth = ""
    constructor() {
        this._login = true
        this.language = GlobalUtil.getDefaultLanguage()
        events.on(AppEvent.Unauthorized, () => {
            this.logout(false)
        })
    }

    initWithLogin = async () => {

        if (this._login) {
            this.getUserInfo()
            this.getAccountInfoWithHashrate()
        }
        this.getCurrencyList()
    }
    getShowCurrency = (): string => {
        const shareCurrency = localStorage.getItem('shareCurrency');
        if (WorkInfoManager.shareUrl && shareCurrency) {
            return shareCurrency.toUpperCase()
        }
        if (this.currency) {
            return this.currency.toUpperCase()
        }
        return ""
    }
    getCurrencyList = async () => {
        try {
            let res = await Api.getCurrencyList()
            if (res.code === ApiCode.SUCCESS) {
                this.currencyList = res.data ?? []
                events.emit(AppEvent.ON_ACCOUNT_INFO_UPDATE, "account");
                events.emit(AppEvent.REFRES, "");
            }
        } catch (error) {

        }

    }
    getUserInfo = async () => {
        try {
            let res = await Api.getUserInfo()
            if (res.code === ApiCode.SUCCESS) {
                let accountInfo = res.data!!
                this.mainAccount = new Account()
                this.mainAccount.account = accountInfo.display_name
                this.mainAccount.email = accountInfo.user_name
                this.mainAccount.isMain = true
                this.mainAccount.hide = false
                this.mainAccount.desc = accountInfo.method ?? ""
                this.mainAccount.hasDuo = accountInfo.has_duo
                this.mainAccount.hasTotp = accountInfo.has_totp
                this.mainAccount.hasWebauthn = accountInfo.has_webauthn
                this.mainAccount.isTwofactorOpen = accountInfo.has_totp
                let settingRes = await Api.getUserSetting()
                if (settingRes.code === ApiCode.SUCCESS) {
                    this.mainAccount.isMaintainerPass = settingRes.data?.isMaintainerPass ?? false
                    this.mainAccount.isTwofactorOpen = settingRes.data?.isTwoFatorOpen ?? false
                }
                events.emit(AppEvent.UPDATE_USERINFO, "");
            }
        } catch (e) {
            console.log("get user info failed, error: " + e);
        }
    }
    getShareCurrencyList = async (shareUrl: string) => {
        try {
            let res = await Api.getCurrencyList(shareUrl)
            if (res.code === ApiCode.SUCCESS) {
                this.shareCurrencyList = res.data ?? []
                events.emit(AppEvent.ON_ACCOUNT_INFO_UPDATE, "account");
            } else {
                events.emit(AppEvent.ERRPR_SHARE_URL);
            }
        } catch (e) {
            console.log(e)
        }

    }
    getShareAccountInfo = async (shareUrl: string) => {
        try {
            let res = await Api.getAccountsWithHashrate(shareUrl)
            if (res.code === ApiCode.SUCCESS) {
                this.shareAccountsWithHashrate = res.data ?? []
                this.sourceShareAccountsWithHashrate = res.data ?? []
                events.emit(AppEvent.ON_ACCOUNT_INFO_UPDATE, "account");
            } else {
                events.emit(AppEvent.ERRPR_SHARE_URL);
            }
        } catch (e) {
            console.log(e)
        }

    }
    getAccountInfoWithHashrate = async () => {
        try {
            let res = await Api.getAccountsWithHashrate()
            if (res.code === ApiCode.SUCCESS) {
                this.accountsWithHashrate = res.data ?? []
                this.sourceAccountsWithHashrate = res.data ?? []
                events.emit(AppEvent.ON_ACCOUNT_INFO_UPDATE, "account");
            }
        } catch (e) {
            console.log("get account info with hash rate failed, error: " + e)
        }
    }

    getAccountInfo = async () => {
        try {
            let res = await Api.getAccounts();
            if (res.code === ApiCode.SUCCESS) {
                let newAccount = UserInfoManager.transformAccount(res.data ?? []);
                this.accounts = newAccount
                events.emit(AppEvent.ON_ACCOUNT_INFO_UPDATE, "account");
            }
        } catch (e) {
            console.log(e)
        }

    }
    setAccountInfoWithHashrate = (v: string) => {
        let reg: RegExp = new RegExp(v, 'i')

        this.accountsWithHashrate = this.sourceAccountsWithHashrate.filter((item) => reg.exec(item.account || "") || reg.exec(item.description || ""))
        this.shareAccountsWithHashrate = this.sourceShareAccountsWithHashrate.filter((item) => reg.exec(item.account || "") || reg.exec(item.description || ""))

    }
    transformAccount = (inputAccounts: Array<ServerAccountListInfo>): Account[] => {
        const newAccounts: Account[] = []
        inputAccounts.forEach((accountInfo) => {
            let account = new Account()
            account.account = accountInfo.name
            account.email = accountInfo.accountName
            account.isMain = accountInfo.isMain
            account.hide = accountInfo.isHide
            account.desc = accountInfo.description ?? ""
            newAccounts.push(account)
        })
        return newAccounts
    }
    login = () => {
        this._login = true
        this.initWithLogin()
    }


    logout = async (needRequest: Boolean = true) => {
        try {
            if (needRequest) {
                let res = await Api.logout()
                if (res.code !== ApiCode.SUCCESS) {
                    return
                }
            }
            this._login = false
            this.accounts = []
            this.currentAccount = ""
            events.emit(AppEvent.LOGOUT, "logout");
        } catch (error) {
        }
    }

    isLogin = () => {
        return this._login
    }
    updateShareHeader = (account: string, currency: string, auth: string) => {
        if (!localStorage.getItem('shareCurrency')) {
            localStorage.setItem('shareCurrency', currency);
        }
        if (this.shareAccount !== account || this.shareAuth !== auth) {
            this.shareAccount = account;
            this.shareAuth = auth;
            events.emit(AppEvent.SHARE_REFRES, "");
        }
    }
    updateWithHeader = (account: string, currency: string, language: string) => {

        let refresh = false
        let accountChange = false
        let currencyChange = false
        let languageChange = false
        if (account && this.currentAccount !== account) {
            this.currentAccount = account
            refresh = true
            accountChange = true
        }
        if (currency && this.currency !== currency) {
            this.currency = currency
            refresh = true
            currencyChange = true
        }

        if (language && this.language !== language) {
            this.language = language
            refresh = true
            languageChange = true
            GlobalUtil.changeLanguage(language)
        }
        if (refresh) {
            events.emit(AppEvent.REFRES, { "account": accountChange, "language": languageChange, "currency": currencyChange });
        }
    }
    updateCurrency = async (newCurrency: string) => {
        try {
            localStorage.setItem('currency', newCurrency);
            if (this.currency === newCurrency) {
                return
            }
            this.currency = newCurrency;
            let res = await Api.setCurEnvInfo(this.currentAccount, newCurrency, this.language);
        } catch (error) {

        } finally {
            events.emit(AppEvent.REFRES, { "currency": true });
        }

    }
    updateShareCurrency = (newCurrency: string) => {
        localStorage.setItem('shareCurrency', newCurrency);
        events.emit('refresh', "");
    }
    updateShareAccout = async (newAccount: string) => {
        if (this.shareAccount === newAccount) {
            return
        }
        this.shareAccount = newAccount
        events.emit(AppEvent.REFRES, newAccount);
    }
    updateAccount = async (newAccount: string) => {
        if (this.currentAccount === newAccount) {
            return
        }
        this.currentAccount = newAccount
        let res = await Api.setCurEnvInfo(this.currentAccount, this.currency, this.language)
        events.emit(AppEvent.REFRES, { "account": true });
    }
    addRefreshListener = () => {

    }

}


const UserInfoManager = new IUserInfoManager()

export { UserInfoManager };;