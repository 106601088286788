import { Row, Image, Col, Dropdown, MenuProps } from "antd"
import { StyleUtils } from "../../utils/Style"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { GlobalUtil } from "../../utils/GlobalText"
import LanguageView from "../../widget/LanguageView"
import StyleModeView from "../../widget/StyleModeView"
import UserView from "../../widget/UserView"
import { UserInfoManager } from "../../utils/UserInfoManager"
import { AppButton, SearchFeat } from "../../views/commonview"
import ShareUserView from "../../widget/ShareUserView"


export const MobileHeader = () => {
    const navigator = useNavigate()
    const location = useLocation();
    let isShare = location.pathname.startsWith("/miner")
    const items: MenuProps['items'] = [
        {
            key: "home",
            label: (
                <Link style={{ color: StyleUtils.theme().fontColor_1 }} to="/home">{GlobalUtil.t('appbar.home')}</Link>
            )
        },
        {
            key: "management",
            label: (
                <Link style={{ color: StyleUtils.theme().fontColor_1 }} to="/work">{GlobalUtil.t('appbar.workmanager')}</Link>
            )
        },
        {
            key: "income",
            label: (
                <Link style={{ color: StyleUtils.theme().fontColor_1 }} to="/revenue">{GlobalUtil.t('appbar.income')}</Link>
            )
        },
    ]

    return (
        <div className="nmob" style={{ background: StyleUtils.theme().boardBg }}>
            <Row justify={"space-between"} align={"middle"} style={{ width: "100%", gap: ".5rem" }}>
                <div className="mlogo" >
                    {/* <Image preview={false} width={121} height={27} src={StyleUtils.ICON} style={{ cursor: "pointer", }} onClick={() => {
                        navigator("/")
                    }} /> */}
                    <Image
                        preview={false}
                        // width={"7.5625rem"}
                        width={27}
                        height={27}
                        src={'../../../logo.jpg'}
                        style={{ cursor: "pointer", }}
                        onClick={() => {
                            navigator("/")
                        }} />
                </div>
                {/* HIDE: */}
                {/* {!isShare && <Row className="user-help" >
                    <img style={{ marginRight: '.25rem' }} src="/assets/icon_issue.svg" width={18} alt="" />
                    <div style={{ color: StyleUtils.theme().fontColor_2, }}>{GlobalUtil.t('appbar.help')}</div>
                </Row>} */}
                <Row className="user-row" align={"middle"} style={{ flexFlow: "row nowrap", flex: "0 1 auto", justifyContent: "flex-end", gap: ".75rem" }}>
                    <div id="language">
                        <LanguageView />
                    </div>
                    <div id="account">
                        {isShare ? <ShareUserView /> : <UserView />}
                    </div>
                    {
                        isShare && <Row className="user-help" >
                            <AppButton style={{ paddingLeft: StyleUtils.getWidth(2), paddingRight: StyleUtils.getWidth(2) }} onClick={() => {
                                UserInfoManager.initWithLogin()
                                navigator("/home")
                            }} text={GlobalUtil.t("appbar.backlogin")} />
                        </Row>
                    }
                    <StyleModeView />
                </Row>

            </Row>
            {<Row className="nmobs" justify={"space-between"}>
                <Col span={22}>
                    <SearchFeat isShare={isShare} data={isShare ? UserInfoManager.shareCurrencyList : UserInfoManager.currencyList} />
                </Col>
                {!isShare && <Col className="nmobs-menu" >
                    <Dropdown
                        menu={{
                            items,
                            selectable: true,
                            defaultSelectedKeys: ['3'],
                        }}
                        placement="bottomRight" arrow={{ pointAtCenter: true }}
                    >
                        {StyleUtils.isLight ?
                            <img src="/assets/menu_dark.svg" width={36} alt="Menu" /> :
                            <img src="/assets/menu_light.svg" width={36} alt="Menu" />
                        }
                    </Dropdown>
                </Col>}
            </Row>}
        </div >
    )
}