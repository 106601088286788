import React, { useEffect, useState } from "react";
import { Card, Input, Row, message } from "antd";
import events from "../../../utils/events";
import { AppEvent } from "../../../data/events";
import { WorkInfoManager } from "../../../utils/WorkData";
import { ApiCode, ServerHashrateHistory, ServerWorker } from "../../../data/common";
import Api from "../../../utils/Api";
import { MoveWorkerModal } from "../modal/MoveWorkerModal";
import { DeleteWorkersModal } from "../modal/DeleteWorkersModal";
import { AppButton } from "../../../views/commonview";
import { StyleUtils } from "../../../utils/Style";
import { WorkListView } from "./WorkListView";
import { GlobalUtil } from "../../../utils/GlobalText";
import './index.css';
import { MobileWorkerInfoViewTable } from "./WorkerInfoViewTable/MobileWorkerInfoViewTable";
import { PcWorkerInfoViewTable } from "./WorkerInfoViewTable/PcWorkerInfoViewTable";
import { WorkGroupDropView } from "./WorkGroupDropView";
import { AntdInput } from "../../../views/commonview";

const { Search } = Input;

interface WorkInfoListViewProp {
}
const WorkInfoListView: React.FC<WorkInfoListViewProp> = (props) => {
    const [update, setUpdate] = useState(false)
    const itemData = WorkInfoManager.getGroupData()

    const [selectWorkers, setSelectWorker] = useState<ServerWorker[]>([])
    const [canDelete, setDelete] = useState(false)
    events.on(AppEvent.GROUP_DATA_CHANGE, () => {
        setSelectWorker([])
        setUpdate(!update)
    })
    events.on(AppEvent.REFRES, () => {
        loadHistoryData()
    })
    const [showMove, setShowMove] = useState(false)
    const [expandWorker, setExpandWorker] = useState<ServerWorker | null>()
    const [historyData, setHistoryData] = useState<ServerHashrateHistory | null>()
    const handleExpand = (record: ServerWorker) => {
        setHistoryData(null)
        if (record.workerName === expandWorker?.workerName) {
            setExpandWorker(null)
        } else {
            setExpandWorker(record)
        }
    }
    const handleIsDelete = (): boolean => {
        var hasOnline = false
        selectWorkers.forEach(v => {
            if (v.state === 1) {
                hasOnline = true
            }
        })
        return !hasOnline && selectWorkers.length !== 0
    }

    const loadHistoryData = async () => {
        if (expandWorker == null) {
            return
        }
        try {
            let res = await Api.getWorkHashrateHistory(WorkInfoManager.shareUrl, expandWorker?.workerName ?? "")

            if (res.code === ApiCode.SUCCESS && res.data?.length) {
                setHistoryData(res.data[0])
            }
        } catch (e) {

        }
    }
    const [showDelete, setShowDelete] = useState(false)
    useEffect(() => {
        loadHistoryData()
    }, [expandWorker])

    useEffect(() => {
        setDelete(handleIsDelete());
    }, [selectWorkers])
    return (
        <Card bodyStyle={{ padding: 0 }} style={{ borderColor: StyleUtils.theme().boardBg, background: StyleUtils.theme().boardBg, display: "flex", flexDirection: "column", width: "100%", borderRadius: WorkInfoManager.shareUrl ? "0" : "" }}>
            <DeleteWorkersModal workers={selectWorkers} onClick={async (ok) => {
                if (ok) {
                    events.emit(AppEvent.GROUP_UPDATE)
                }
                setShowDelete(false)
            }} show={showDelete} />
            <MoveWorkerModal onClick={(ok) => {
                if (ok) {
                    events.emit(AppEvent.GROUP_UPDATE)
                }
                setShowMove(false)
            }} show={showMove} groups={WorkInfoManager.getGroupData()?.group ?? []} worker={selectWorkers} />
            {!WorkInfoManager.shareUrl && <WorkListView />}
            {WorkInfoManager.shareUrl && <div style={{ width: 1, height: StyleUtils.getHeight(16) }} />}
            <div className="WorkerTable-status" style={{ paddingLeft: StyleUtils.getWidth(12), paddingRight: StyleUtils.getWidth(12), }}>
                <Row justify={"start"} style={{ flexFlow: "row nowrap", }}>
                    {[GlobalUtil.t("work.all"), GlobalUtil.t("work.online"), GlobalUtil.t("work.offline"), GlobalUtil.t("work.dead")].flatMap((v, k) => {
                        let isSelect = WorkInfoManager.status === k + ""
                        let value: number | undefined = 0
                        if (k === 0) {
                            value = itemData?.state?.allSize
                        } else if (k === 1) {
                            value = itemData?.state?.onlineSize
                        } else if (k === 2) {
                            value = itemData?.state?.offlineSize
                        } else if (k === 3) {
                            value = itemData?.state?.deadSize
                        }
                        return <div key={k} style={{ alignItems: "center", marginRight: StyleUtils.getWidth(23), cursor: "pointer", display: "flex", flexDirection: "column" }} onClick={() => {
                            WorkInfoManager.updateStatus(k.toString())
                        }}>
                            <div style={{ color: isSelect ? StyleUtils.theme().mainButtonColor : StyleUtils.theme().fontColor_2, fontSize: ".88rem" }}>{v}({value ?? 0})</div>
                            <div style={{ borderRadius: "8px", width: StyleUtils.getWidth(12), height: StyleUtils.getHeight(3), background: isSelect ? StyleUtils.theme().mainButtonColor : "", marginTop: StyleUtils.getHeight(8) }} />
                        </div>
                    })}
                </Row>

                <div className="WorkerTable-operate" style={{ flex: WorkInfoManager.shareUrl ? undefined : "auto" }}>
                    {WorkInfoManager.shareUrl && <WorkGroupDropView />}
                    <AntdInput>
                        <Search
                            className="searchb"
                            style={{
                                marginRight: StyleUtils.getWidth(2),
                                border: `1px solid ${StyleUtils.theme().mainColor}`,
                                color: StyleUtils.theme().fontColor_2
                            }}

                            onSearch={(e) => {
                                WorkInfoManager.updateSearch(e)
                            }}
                            autoComplete="off"
                            placeholder={GlobalUtil.t("work.searchTip")}
                            enterButton
                        />
                    </AntdInput>
                    {!WorkInfoManager.shareUrl &&
                        <AppButton
                            style={{
                                marginRight: StyleUtils.getWidth(2),
                                padding: "4px 12px",
                                backgroundColor: !selectWorkers.length ? StyleUtils.theme().disabledButtoinColor : StyleUtils.theme().mainButtonColor

                            }}
                            disabled={!selectWorkers.length}
                            onClick={() => {
                                setShowMove(true)
                            }}
                            text={GlobalUtil.t("work.moveWork")}
                        />
                    }
                    {!WorkInfoManager.shareUrl &&
                        <AppButton
                            text={GlobalUtil.t("work.deleteWork")}
                            disabled={!canDelete}
                            style={{
                                padding: "4px 12px",
                                backgroundColor: !selectWorkers.length || !canDelete ? StyleUtils.theme().disabledButtoinColor : StyleUtils.theme().mainButtonColor
                            }}
                            onClick={() => {
                                setShowDelete(true)
                            }}
                        />
                    }
                </div>


            </div>
            <div style={{ height: StyleUtils.getHeight(1), width: "100%", background: StyleUtils.theme().dividerColor }} />

            <div className="PcWorkerTable">
                <PcWorkerInfoViewTable
                    setSelectWorker={setSelectWorker}
                    selectWorkers={selectWorkers}
                    WorkInfoManager={WorkInfoManager}
                    handleExpand={handleExpand}
                    itemData={itemData}
                    expandWorker={expandWorker}
                    historyData={historyData} />
            </div>

            <div className="MobileTable">
                <MobileWorkerInfoViewTable
                    setSelectWorker={setSelectWorker}
                    handleExpand={handleExpand}
                    itemData={itemData}
                    expandWorker={expandWorker}
                    historyData={historyData}
                    WorkInfoManager={WorkInfoManager}
                />
            </div>

        </Card>
    )
}

interface WorkInfoViewProp {
}

const WorkInfoView: React.FC<WorkInfoViewProp> = (props) => {

    return (
        <WorkInfoListView />
    )
}

export default WorkInfoView