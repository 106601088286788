import { Dispatch, FC, useEffect, useState } from "react";
import { OnlyReadData, ServerWorker } from "../../../data/common";
import { Row, Checkbox, Pagination, Col, Divider, Space, Dropdown, QRCode } from "antd";
import { StyleUtils } from "../../../utils/Style";
import { AntdInput } from "../../../views/commonview";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { deepCopy } from "../../../utils/deepCopy";
import moment from "moment";
import { EditOutlined, CopyOutlined, QrcodeOutlined, DeleteOutlined, SettingOutlined } from "@ant-design/icons";
import { OnlyDataAction } from "./OnlyReadView";
import { GlobalUtil } from "../../../utils/GlobalText";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";

interface ReadOnlyProps {
    data: OnlyReadData[]
    deleteOnlyRead: (id: string) => void
    setSelectData: Dispatch<React.SetStateAction<OnlyReadData[] | null>>
    settingOpen?: OnlyReadData | null
    setSettingOpen: React.Dispatch<React.SetStateAction<OnlyReadData | null>>
    setOnlyDataAction: React.Dispatch<React.SetStateAction<OnlyDataAction | null | undefined>>
}
export const MobileReadOnlyList: FC<ReadOnlyProps> = ({
    data,
    setSelectData,
    deleteOnlyRead,
    settingOpen,
    setSettingOpen,
    setOnlyDataAction
}) => {
    const [checkedList, setCheckedList] = useState<CheckboxValueType[]>([])
    const [indeterminate, setIndeterminate] = useState(false);
    const [options, setOptions] = useState<string[]>([])
    const {xs} = useBreakpoint()
    const [checkAll, setCheckAll] = useState(false);
    const [currentPage, setCurrentPage] = useState(1)
    const handleClickAll = (e: CheckboxChangeEvent) => {
        setCheckedList(e.target.checked ? options : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
        if (e.target.checked) {
            setSelectData(data)
        } else {
            setSelectData([])
        }
    }

    const handleClick = (e: CheckboxChangeEvent, item: OnlyReadData) => {
        const { checked } = e.target;

        const list = checked ? [...deepCopy(checkedList), item.id] : deepCopy(checkedList).filter((id) => id !== item.id)

        setCheckedList(list);

        setSelectData((prev) =>
            checked
                ? [...prev || [] , item]
                : (prev || []).filter((i) => i !== item)
        )

        setCheckAll(list.length === options.length)
        setIndeterminate(!!list.length && list.length < options.length)

    }

    useEffect(() => {
        if (data) {
            const opts = data.map((item) => item.id)
            setOptions(opts)
        }
    }, [data])

    return (
        <Row >
            <Row justify={"space-between"} style={{ flexFlow: "row wrap-reverse", rowGap: "10px", margin: "8px 8px 12px", width: "100%" }}>
                <Checkbox className={StyleUtils.isLight ? "ckd" : "ckl"}
                    style={{ color: StyleUtils.theme().fontColor_1 }}
                    indeterminate={indeterminate}
                    checked={checkAll}
                    onChange={handleClickAll} >
                    Check All
                </Checkbox>
                <AntdInput>
                    <Pagination
                        size="small"
                        total={data ? data.length : 0}
                        current={currentPage}
                        defaultPageSize={5}
                        showSizeChanger
                        showQuickJumper
                        onChange={(page) => {
                            setCurrentPage(page)
                        }} />
                </AntdInput>

            </Row>
            {
                data.flatMap((item, index) => {
                    let columnData = [
                        [GlobalUtil.t("account.account"), item.accounts],
                        [GlobalUtil.t("account.desc"), item.currencies],
                        [GlobalUtil.t("onlyread.permission"), item.accounts],
                        [GlobalUtil.t("onlyread.currency"), item.accessPermission],
                        [GlobalUtil.t("onlyread.link"), item.url],
                        // ["操作", item.url ],
                    ]
                    return (          
                            <div key={item.id + index} style={{ width: "100%", display: "flex", padding: "10px 8px", flexDirection: "column", border: `1px solid ${StyleUtils.getBorderColor()}`, marginBottom: "8px", borderRadius: "8px", ...StyleUtils.fontStyle_1() }} >
                                <Row justify={'space-between'} style={{ width: "100%" }}>
                                    <Col style={{ margin: 0 }}>
                                        <Checkbox
                                            className={StyleUtils.isLight ? "ckd" : "ckl"}
                                            checked={checkedList.includes(item.id)}
                                            onChange={(e) => {
                                                handleClick(e, item)
                                            }}
                                        />
                                    </Col>
                                    <Dropdown

                                        open={settingOpen?.id === item.id}
                                        placement={"bottomCenter"}
                                        trigger={["click"]} onOpenChange={(open) => {
                                            if (!xs) return
                                            if (open) {
                                                setSettingOpen(item)
                                            } else {
                                                setSettingOpen(null)
                                            }
                                        }}
                                        autoFocus
                                        dropdownRender={(props) => {
                                            return <div style={{ boxShadow: '1px 2px 9px ' + StyleUtils.theme().boardB2, background: StyleUtils.theme().boardBg, display: "flex", flexDirection: "row", borderRadius: StyleUtils.getWidth(6) }}>
                                                <EditOutlined onClick={(e) => {
                                                    e.preventDefault()
                                                    setSettingOpen(null)
                                                    setOnlyDataAction(new OnlyDataAction(true, item))
                                                }} style={{ width: "50px", height: "50px", display: "flex", justifyContent: "center", alignItems: "center" }} />
                                                <CopyOutlined onClick={() => {
                                                    navigator.clipboard.writeText(item.url ?? "")
                                                    setSettingOpen(null)
                                                }} style={{ width: "50px", height: "50px", display: "flex", justifyContent: "center", alignItems: "center" }} />
                                                <Dropdown
                                                    placement={"bottomCenter"}
                                                    dropdownRender={() => {
                                                        return <QRCode value={item.url} />;
                                                    }}
                                                >
                                                    <QrcodeOutlined onClick={() => {
                                                        setSettingOpen(null)
                                                    }} style={{ width: "50px", height: "50px", display: "flex", justifyContent: "center", alignItems: "center" }} />
                                                </Dropdown>
                                                <DeleteOutlined onClick={() => {
                                                    setSettingOpen(null)
                                                    deleteOnlyRead(item.id)
                                                }} style={{ width: "50px", height: "50px", display: "flex", justifyContent: "center", alignItems: "center" }} />
                                            </div>
                                        }}
                                    >
                                        <SettingOutlined style={{ color: StyleUtils.theme().mainButtonColor }} />
                                    </Dropdown>
                                </Row>
                                <Divider style={{ borderBlockStart: `1px solid ${StyleUtils.theme().dividerColor}` }} />
                                <Row style={{ flexDirection: "column", gap: "8px", flexFlow: "column" }} justify={"start"}>
                                    {columnData.map((item, index) => (
                                        <Row key={index} justify={"space-between"} style={{ flexFlow: "row nowrap", columnGap: "1rem" }}>
                                            <Col style={{ fontWeight: "500", color: StyleUtils.theme().fontColor_3 }}>
                                                {item[0]}
                                            </Col>
                                            <Col style={{ display: "flex", overflowWrap: "anywhere", fontSize: "0.88rem", fontWeight: "400", color: StyleUtils.theme().fontColor_1 }}>
                                                {item[1]}
                                            </Col>
                                        </Row>
                                    ))}

                                </Row>
                            </div>
                        
                    )
                })
            }
        </Row>
    )
}